import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { jwtService } from "app/auth/jwtService";
import { setSelectedTeamIds, setTeamList } from "store/ticketFetchSlice";
import { Box } from "@mui/material";
import settingsConfig from "app/configs/settingConfig";
import { fetchAllTeams } from "app/Api Calls/TeamsRequiredApis";

const TeamFilter = () => {
    const dispatch = useDispatch();
    const selectedTeams = useSelector((state) => state.ticketFetchSlice.selectedTeamIds);
    const { assignToMe, assignToTeam } = useSelector((state) => state.ticketFetchSlice.assignment);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
    const rawTeams = useSelector((state) => state.ticketFetchSlice.teamList);

    const currentUser = jwtService.getUser();
    const isAdmin = settingsConfig.roles.admin.includes(currentUser.roleName);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Fetch all teams if the user is an admin
    useEffect(() => {
        if (isAdmin && rawTeams.length === 0) {
            const fetchTeams = async () => {
                setLoading(true);
                try {
                    const teams = await fetchAllTeams();
                    dispatch(setTeamList(teams));
                } catch (error) {
                    console.error("Failed to fetch teams:", error);
                    dispatch(setTeamList([]));
                } finally {
                    setLoading(false);
                }
            };

            fetchTeams();
        }
    }, [dispatch, isAdmin, rawTeams]);

    const userTeams = isAdmin ? rawTeams : currentUser?.teams || [];

    const isDisabled = useMemo(() =>  ticketsLoading, [
         ticketsLoading
    ]);

    const groupedTeams = useMemo(() => {
        return userTeams.reduce((acc, team) => {
            let groupRoleName;

            if (isAdmin) {
                groupRoleName = settingsConfig.groupRoles.find(gr => gr.id === team.groupRoleId)?.name ||
                    `Άγνωστη Ομάδα (${team.groupRoleId})`;
            } else {
                groupRoleName = team.groupRole?.name || `Άγνωστη Ομάδα (${team.groupRoleId})`;
            }

            if (!acc[groupRoleName]) {
                acc[groupRoleName] = [];
            }

            acc[groupRoleName].push(team);
            return acc;
        }, {});
    }, [userTeams, isAdmin]);

    const memoizedTeams = useMemo(() => {
        const selectedTeamsGrouped = selectedTeams.reduce((acc, team) => {
            const groupRoleName = team.groupRole?.name || `Άγνωστη Ομάδα (${team.groupRoleId})`;

            if (!acc[groupRoleName]) {
                acc[groupRoleName] = [];
            }

            acc[groupRoleName].push(team);
            return acc;
        }, {});

        const otherTeamsGrouped = Object.keys(groupedTeams).reduce((acc, groupName) => {
            acc[groupName] = groupedTeams[groupName].filter(team =>
                !selectedTeams.some(selected => selected.id === team.id)
            );
            return acc;
        }, {});

        return {
            selected: selectedTeamsGrouped,
            others: otherTeamsGrouped
        };
    }, [groupedTeams, selectedTeams]);

    const sortedOptions = useMemo(() => {
        const options = [];

        Object.keys(memoizedTeams.selected).forEach(groupName => {
            options.push(...memoizedTeams.selected[groupName].map(team => ({
                ...team,
                groupName: `Επιλεγμένες - ${groupName}`
            })));
        });

        Object.keys(memoizedTeams.others).forEach(groupName => {
            options.push(...memoizedTeams.others[groupName].map(team => ({
                ...team,
                groupName
            })));
        });

        return options.sort((a, b) => {
            if (a.groupName.startsWith('Επιλεγμένες') && !b.groupName.startsWith('Επιλεγμένες')) return -1;
            if (!a.groupName.startsWith('Επιλεγμένες') && b.groupName.startsWith('Επιλεγμένες')) return 1;
            if (a.groupName < b.groupName) return -1;
            if (a.groupName > b.groupName) return 1;
            return a.name.localeCompare(b.name);
        });
    }, [memoizedTeams]);

    const handleChange = useCallback((event, newValues) => {
        dispatch(setSelectedTeamIds(newValues));
    }, [dispatch]);

    const handleOpen = useCallback(() => {
        if (!isDisabled && !open) setOpen(true);
    }, [isDisabled, open]);

    const handleClose = useCallback(() => {
        if (open) setOpen(false);
    }, [open]);

    return (
        <Box>
            <Autocomplete
                multiple
                disablePortal
                id="teams-autocomplete"
                options={sortedOptions}
                groupBy={(option) => option.groupName}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                readOnly={isDisabled}
                ListboxProps={{ style: { padding: 0 } }}
                loading={loading}
                sx={{
                    flexGrow: 1,
                    minWidth: '150px',
                    maxWidth: '100%',
                    width: 'auto',
                    flexShrink: 1,
                    height: '42px',
                    '.MuiOutlinedInput-root': {
                        height: 42,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '0 10px',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    },
                    '.MuiAutocomplete-inputRoot': {
                        padding: '0',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }
                }}

                value={selectedTeams ?? []}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ομάδα"
                        aria-disabled={isDisabled}
                        onFocus={handleOpen}
                        onBlur={handleClose}
                        onClick={handleOpen}
                    />
                )}
                renderGroup={(params) => (
                    <li key={params.key} style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        <ul>
                            <li style={{
                                fontWeight: 'bold',
                                backgroundColor: '#f7f7f7',
                                position: 'sticky',
                                top: 0,
                                zIndex: 2,
                                boxShadow: '0 2px 2px -1px rgba(0,0,0,0.2)',
                            }}>
                                {params.group}
                            </li>
                            {params.children}
                        </ul>
                    </li>
                )}
            />
            {isDisabled && !ticketsLoading && (
                <div style={{ color: 'red', marginTop: '8px' }}>
                    {assignToMe && 'Πρέπει να απενεργοποιήσετε την επιλογή Ανατεθειμένα σε μένα'}
                    {assignToTeam && 'Πρέπει να απενεργοποιήσετε την επιλογή Ανατεθειμένα στην Ομάδα μου'}
                </div>
            )}
        </Box>
    );
}

export default TeamFilter;
