import React, {useState} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Divider,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserMenu from './UserMenu';
import drawerMenuItems from './DrawerMenuItems';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import ReleaseNotesDialog from "./ReleaseNotes";

const drawerWidth = 240;

const DrawerWrapper = ({children}) => {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const isActive = (url) => location.pathname === `/${url}`;
    const navigate = useNavigate();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const mainMenuItems = drawerMenuItems.filter(item => item.id !== 'download-link' && item.id !== 'release-notes');
    const bottomMenuItems = drawerMenuItems.filter(item => item.id === 'download-link' || item.id === 'release-notes');
    const isSubmittingTicket = useSelector((state) => state.ticketSlice.isSubmitingTicket);
    const isLoadingBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.isLoadingBeneficiary);
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleListItemClick = (item) => {
        if (item.action === 'openDialog') {
            setDialogOpen(true);
        } else if (item.action === 'download') {
            window.open(item.url, '_blank');
        } else {
            navigate(`/${item.url}`);
        }
    };

    return (
        <Box>
            <AppBar position="fixed" sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
                transition: 'width 0.2s ease',
                ml: `${drawerOpen ? drawerWidth : 0}px`,
                backgroundColor: '#ffffff'
            }}>
                <Toolbar disableGutters>
                    {!drawerOpen && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{marginRight: 2, color: '#212121', marginLeft: 1}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    {/* Spacer to push UserMenu to the right */}
                    <Box sx={{flexGrow: 1}}/>

                    <UserMenu/>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="persistent"
                open={drawerOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    transition: 'width 0.3s ease',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#003476',
                        color: 'white'
                    },
                }}
            >
                {/* Drawer content */}
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Box sx={{opacity: drawerOpen ? 1 : 0, transition: 'opacity 0.3s ease',  display: 'flex', height: "50%",
                        justifyContent: 'center'
                    }}>
                        <img src="/logo/mainlogo.webp" alt="User Image" />
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        onClick={handleDrawerToggle}
                        sx={{color: 'white'}}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
                <Divider/>

                <div style={{
                    padding: '16px',
                    backgroundColor: '#003476',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography variant="subtitle1" style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                        {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.username}
                    </Typography>

                    <Typography variant="subtitle2"
                                style={{color: 'gray', textAlign: 'center', fontSize: '12px', fontWeight: 'bold'}}>
                        {user.email}
                    </Typography>
                </div>
                <Box sx={{overflow: 'auto'}}>
                    <List>
                        {mainMenuItems.map((item) => {
                            // Determine if the current item is active
                            const isActiveItem = isActive(item.url);
                            return (
                                <ListItem
                                    key={item.id}
                                    disabled={isSubmittingTicket || isLoadingBeneficiary}
                                    onClick={() => handleListItemClick(item)}
                                    sx={{
                                        transition: 'colors 0.3s',
                                        bgcolor: isActiveItem ? '#003170' : 'transparent',
                                        color: isActiveItem ? 'white' : '#b3c2d6',
                                        '&:hover': {
                                            bgcolor: '#003170',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{color: isActiveItem ? 'white' : 'inherit'}}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>

                <Box sx={{mt: 'auto'}}>
                    <Divider/>
                    <List>
                        {bottomMenuItems.map((item) => {
                            // Determine if the current item is active
                            const isActiveItem = isActive(item.url);
                            return (
                                <ListItem
                                    key={item.id}
                                    disabled={isSubmittingTicket || isLoadingBeneficiary}
                                    onClick={() => handleListItemClick(item)}
                                    sx={{
                                        transition: 'colors 0.3s',
                                        bgcolor: isActiveItem ? '#003170' : 'transparent', // Use isActiveItem for condition
                                        color: isActiveItem ? 'white' : '#b3c2d6',
                                        '&:hover': {
                                            bgcolor: '#003170',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{color: isActiveItem ? 'white' : 'inherit'}}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>

            </Drawer>
            <ReleaseNotesDialog open={isDialogOpen} onClose={() => setDialogOpen(false)}/>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, ml: `${drawerOpen ? drawerWidth : 0}px`, mt: 8}}
            >
                {children}
            </Box>
        </Box>
    );
};

export default DrawerWrapper;
