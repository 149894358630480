import {axiosApi} from "app/configs/axiosConfig";

export const createAppointment = async (ticketId, scheduledDateTime) => {
    try {
        const payload = {
            ticketId,
            scheduledDateTime,
        };
        const response = await axiosApi.post("/appointment", payload);
        return response.data;
    } catch (error) {
        console.error("Failed to create appointment:", error);
        throw error;
    }
};