import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    Grid,
    CircularProgress,
    InputLabel,
    Autocomplete,
    Popper,
    LinearProgress, Chip, FormControlLabel, Checkbox,
} from "@mui/material";
import {
    fetchUsersRoleBasedAsync,
    resetUsers,
    setUsers,
} from "store/teamsSlice";
import {
    fetchThematicByCarrierId,
    updateTeam,
} from "app/Api Calls/TeamsRequiredApis";
import {fetchSubSubthematic} from "app/Api Calls/CarriersThematicsCalls";
import {showMessage} from "store/messageSlice";
import {styled} from "@mui/system";

const TeamEditDialog = ({team, open, onClose, loading, onUpdateComplete}) => {
    const HighlightedOption = styled('div')(({theme}) => ({
        backgroundColor: 'yellow', // Set to yellow or any color you prefer for highlighting
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        padding: '6px 16px',
        borderRadius: '4px',
    }));


    // Redux dispatch
    const dispatch = useDispatch();

    // Refs
    const fetchedUsersRef = useRef([]);
    const currentPageRef = useRef(0);

    // Component States
    const [saveLoading, setSaveLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [teamName, setTeamName] = useState("");
    const [teamNameEn, setTeamNameEn] = useState("");
    const [sourceApplication, setSourceApplication] = useState([]);
    const [geography, setGeography] = useState([]);
    const [assignmentMethod, setAssignmentMethod] = useState("");
    const [groupRole, setGroupRole] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [carriersThematics, setCarriersThematics] = useState([]);
    const [isUsersLoading, setIsUsersLoading] = useState(true);
    const [highlightedUser, setHighlightedUser] = useState(null);
    const [servesTelco, setServesTelco] = useState(false);

    // Refs for each chip
    const chipRefs = useRef({});
    // Redux Selectors
    const teamGeographies = useSelector(
        (state) => state.teamsSlice.teamGeographies
    );
    const teamAssignmentMethods = useSelector(
        (state) => state.teamsSlice.teamAssignmentMethods
    );
    const carrierSets = useSelector((state) => state.teamsSlice.carrierSets);
    const carriers = carrierSets.reduce((acc, set) => [...acc, ...set.carriers], []);

    const users = useSelector((state) => state.teamsSlice.users);
    const groupRoles = useSelector((state) => state.teamsSlice.groupRoles);
    const availableSourcesApplication = useSelector(
        (state) => state.teamsSlice.availableSourcesApplication
    );
    const [isCarriersThematicsLoading, setIsCarriersThematicsLoading] = useState(true);
    const fetchCarrierThematicData = async (carrier) => {
        const allThematics = await fetchThematicByCarrierId(carrier.id);

        // Extract the subthematics from the thematicCollection.
        const subthematicsInCollection = carrier.thematicCollection
            .filter(thematic => thematic.parentId !== null);

        // Identify parent IDs of these subthematics that are not already present in thematicCollection.
        const missingParentIds = subthematicsInCollection
            .map(subthematic => subthematic.parentId)
            .filter(parentId => !carrier.thematicCollection.some(thematic => thematic.id === parentId));

        // Remove duplicates by converting to a Set and then back to an array.
        const uniqueMissingParentIds = [...new Set(missingParentIds)];

        // Fetch these missing parent thematics.
        const fetchedMissingThematics = await Promise.all(uniqueMissingParentIds.map(id => fetchSubSubthematic(id)));

        // Merge with the thematics.
        const mergedThematics = [...allThematics, ...fetchedMissingThematics];

        // Create a map to store subthematics by parent ID for efficient access
        const subthematicsByParentId = {};

        // Fetch child thematics for parent thematics and organize them by parent ID
        for (const thematic of mergedThematics) {
            if (thematic.parentId === null) { // Check if the thematic is a parent
                const childThematics = await fetchSubSubthematic(thematic.id);
                subthematicsByParentId[thematic.id] = childThematics;
            }
        }

        // Flatten the subthematics by parent ID to get allSubThematics
        const allSubThematics = Object.values(subthematicsByParentId).flat();

        // Remove duplicate subthematics
        const uniqueAllSubThematics = allSubThematics.filter((subthematic, index, self) =>
            index === self.findIndex((s) => s.id === subthematic.id)
        );

        const selectedSubthematics = {};

        // For each thematic, determine the selected subthematics
        mergedThematics.forEach(thematic => {
            const thematicId = thematic.id;
            const specificSubthematicsForThematic = allSubThematics.filter(subthematic => subthematic.parentId === thematicId);

            if (specificSubthematicsForThematic.length === 0) {
                selectedSubthematics[thematicId] = [];
            } else {
                selectedSubthematics[thematicId] = specificSubthematicsForThematic.filter(subthematic =>
                    subthematicsInCollection.some(selectedSub => selectedSub.id === subthematic.id)
                ).map(subthematic => subthematic.id);
            }
        });

        return {
            carrier: {
                id: carrier.id,
                title: carrier.title,
            },
            allThematics: mergedThematics,
            selectedThematics: [...carrier.thematicCollection.map(t => t.id), ...uniqueMissingParentIds],
            allSubThematics: uniqueAllSubThematics,
            selectedSubthematics
        };
    };


    const sortedTeamGeographies = useMemo(() => {
        return teamGeographies
            .slice() // Create a copy to prevent mutating the original array
            .sort((a, b) => {
                // Find the carriers for each geography
                const carrierA = carriers.find(carrier => carrier.id === a.carrierId);
                const carrierB = carriers.find(carrier => carrier.id === b.carrierId);

                // Compare by carrier title first
                const carrierTitleA = carrierA ? carrierA.title : '';
                const carrierTitleB = carrierB ? carrierB.title : '';
                if (carrierTitleA !== carrierTitleB) {
                    return carrierTitleA.localeCompare(carrierTitleB);
                }

                // If carrier titles are the same, then sort by geography name
                return a.name.localeCompare(b.name);
            });
    }, [teamGeographies, carriers]);


    const fetchMissingSubthematicsForCarrier = async (carrierThematic, newSelectedThematics) => {
        const {allSubThematics} = carrierThematic;

        // Find out the newly selected thematics
        const newThematics = newSelectedThematics.filter(id => !carrierThematic.selectedThematics.includes(id));

        // IDs of subthematics we have
        const presentSubThematicsIds = allSubThematics.map(sub => sub.parentId);

        // IDs of subthematics we need to fetch
        const toFetch = newThematics.filter(id => !presentSubThematicsIds.includes(id));

        // Fetch missing subthematics
        for (let thematicId of toFetch) {
            try {
                const response = await fetchSubSubthematic(thematicId);
                const data = await response;

                // Add these to the allSubThematics of the specific carrierThematic
                setCarriersThematics(prev =>
                    prev.map(item =>
                        item.carrier.id === carrierThematic.carrier.id
                            ? {...item, allSubThematics: [...allSubThematics, ...data]}
                            : item
                    )
                );
            } catch (error) {
                console.log(error)
            }
        }
    }


    useEffect(() => {
        if (team) {
            setTeamName(team.name);
            setTeamNameEn(team.nameEn);
            setSourceApplication(team.sourceApplicationCollection.map(item => item.id));
            setGeography(Array.isArray(team.geographyCollection) && team.geographyCollection.length === 0 ? ['none'] : team.geographyCollection.map(item => item.id));
            setAssignmentMethod(team.assignmentMethod.code);
            setGroupRole(team.groupRole.id);
            setSelectedUsers(team.userCollection);
            if (team.servesTelco) {
                setServesTelco(true);
            }
            setIsCarriersThematicsLoading(true);

            Promise.all(
                team.carriersWithThematicsDto.map(carrier => fetchCarrierThematicData(carrier))
            ).then(carrierThematicArrays => {
                setCarriersThematics(carrierThematicArrays);
                // Set loading to false after all data is fetched
                setIsCarriersThematicsLoading(false);
            }).catch(error => {
                console.error('Error fetching carrier thematic data:', error);
                setIsCarriersThematicsLoading(false);
            });
        }
    }, [team]);

    //Role abbreviation to show near the lastname
    const getRoleAbbreviation = (roleName) => {
        const roleMap = {
            'ROLE_ADMIN': 'Admin',
            'ROLE_FO_SUPERVISOR': 'FO-S',
            'ROLE_BO_SUPERVISOR': 'BO-S',
            'ROLE_SA_SUPERVISOR': 'SA-S',
            'ROLE_FO_TEAM_LEADER': 'FO-TL',
            'ROLE_BO_TEAM_LEADER': 'BO-TL',
            'ROLE_SA_TEAM_LEADER': 'SA-TL',
            'ROLE_FO_AGENT': 'FO-Agent',
            'ROLE_BO_AGENT': 'BO-Agent',
            'ROLE_SA_AGENT': 'SA-Agent',
        };

        // Assuming each user has one primary role for simplicity; adjust logic as needed for multiple roles
        return roleMap[roleName] || roleName;
    };

    useEffect(() => {
        const fetchUsers = async () => {
            if (open && groupRole) {
                let keepFetching = true;
                setIsUsersLoading(true);
                while (keepFetching) {
                    const result = await dispatch(
                        fetchUsersRoleBasedAsync({
                            page: currentPageRef.current,
                            size: 100,
                            requestBody: {groupRoleId: groupRole},
                        })
                    );

                    if (result.type.includes("rejected")) {
                        return;
                    }

                    fetchedUsersRef.current = [
                        ...fetchedUsersRef.current,
                        ...result.payload.elements,
                    ];

                    // Check if more pages are available
                    if (result.payload.totalPages > currentPageRef.current + 1) {
                        currentPageRef.current++;
                    } else {
                        keepFetching = false;
                    }
                }
                const sortedUsers = fetchedUsersRef.current.sort((a, b) => a.lastName.localeCompare(b.lastName));
                dispatch(setUsers(sortedUsers));
                setCurrentPage(currentPageRef.current);
                setIsUsersLoading(false);
            }
        };

        // Reset for a new fetch cycle
        fetchedUsersRef.current = [];
        currentPageRef.current = 0;
        fetchUsers();

    }, [dispatch, groupRole, open]);


    // Scroll to the highlighted chip when the highlightedUser changes
    useEffect(() => {
        if (highlightedUser && chipRefs.current[highlightedUser.id]) {
            chipRefs.current[highlightedUser.id].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [highlightedUser]);

    const handleSelectChange = (setStateFunc) => (event) => {
        let selectedValues = event.target.value;

        // If "none" is in the list along with other values, remove it
        if (selectedValues.includes("none") && selectedValues.length > 1) {
            selectedValues = selectedValues.filter((value) => value !== "none");
        }

        // If no values are selected, set to an empty array
        if (selectedValues.length === 0) {
            selectedValues = [];
        }

        setStateFunc(selectedValues);
    };

    const handleMultiSelectChange = (setStateFunc) => (event) => {
        const value = event.target.value;
        setStateFunc(value);
    };

    const generateThematicCollection = () => {
        const thematicsToSend = [];

        carriersThematics.forEach((carrierThematic) => {
            const allValidThematicIds = carrierThematic.allThematics.map(t => t.id);

            carrierThematic.selectedThematics.forEach((thematicId) => {
                if (!allValidThematicIds.includes(thematicId)) return;
                const allSubthematicsForThematic = carrierThematic.allSubThematics.filter(st => st.parentId === thematicId);
                const selectedSubthematicsForThematic = carrierThematic.selectedSubthematics[thematicId] || [];

                if (allSubthematicsForThematic.length === selectedSubthematicsForThematic.length) {
                    // All subthematics are selected
                    thematicsToSend.push({thematicId});
                } else if (selectedSubthematicsForThematic.length === 0) {
                    // None of the subthematics are selected
                    thematicsToSend.push({thematicId});
                } else {
                    // Only some subthematics are selected
                    selectedSubthematicsForThematic.forEach(subthematicId => {
                        if (allValidThematicIds.includes(subthematicId)) return;
                        thematicsToSend.push({thematicId: subthematicId});
                    });
                }
            });
        });

        return thematicsToSend;
    };


    const handleSubmit = async (e) => {
        setSaveLoading(true);
        e.preventDefault();
        let geographyCollection;
        if (
            geography.length === 0 ||
            (geography.length === 1 && geography[0] === "none")
        ) {
            geographyCollection = [];
        } else {
            geographyCollection = geography.map((id) => ({id}));
        }

        const teamData = {
            id: team.id,
            name: teamName,
            nameEn: teamNameEn,
            sourceApplicationCollection: Array.isArray(sourceApplication)
                ? sourceApplication.map((id) => ({id}))
                : [{id: sourceApplication}],
            userCollection: selectedUsers.map((user) => ({id: user.id})),
            geographyCollection,
            thematicCollection: generateThematicCollection(),
            assignmentMethodCode: assignmentMethod,
            groupRoleId: groupRole,
            ...(servesTelco && { servesTelco: true }),
        };

        try {
            // console.log(teamData);
            const response = await updateTeam(teamData);
            if (response.status === 200) {
                // Dispatch success message
                dispatch(
                    showMessage({
                        message: "Επιτυχής Ενημέρωση Ομάδας.",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    })
                );
                setSaveLoading(false);
                onUpdateComplete();
                handleClose();
            }
        } catch (error) {
            // Dispatch error message
            dispatch(
                showMessage({
                    message: "Αποτυχία Ενημέρωσης Ομάδας.",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
            setSaveLoading(false);
            console.error(error);
        }
    };

    const handleClose = () => {
        // Reset all states to their initial values
        setSaveLoading(false);
        setCurrentPage(0);
        setTeamName("");
        setTeamNameEn("");
        setSourceApplication([]);
        setGeography([]);
        setAssignmentMethod("");
        setGroupRole("");
        setSelectedUsers([]);
        setCarriersThematics([]);
        setIsUsersLoading(true);
        setHighlightedUser(null);
        fetchedUsersRef.current = [];
        currentPageRef.current = 0;

        dispatch(resetUsers());
        onClose();
    };


    return (
        <Dialog
            PaperProps={{
                style: {width: "80vw", maxWidth: "none", margin: "auto"},
            }}
            open={open}
            onClose={onClose}
        >
            {loading || saveLoading ? (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{minHeight: "60vh"}}
                >
                    <CircularProgress/>
                </Grid>
            ) : (
                <div style={{maxWidth: "100%", overflow: "auto"}}>
                    <DialogTitle>Επεξεργασία Ομάδας</DialogTitle>
                    <DialogContent sx={{paddingTop: "10px !important"}}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField
                                    label="Όνομα"
                                    value={teamName}
                                    onChange={(e) => setTeamName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Όνομα (Αγγλικά)"
                                    value={teamNameEn}
                                    onChange={(e) => setTeamNameEn(e.target.value)}
                                    fullWidth
                                    style={{maxWidth: "100%"}}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={servesTelco}
                                            onChange={(e) => setServesTelco(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Εξυπηρετεί Αιτήματα Βίντεο Κλήσης"
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Πηγή Εφαρμογής</InputLabel>
                                <Select
                                    multiple
                                    value={sourceApplication}
                                    onChange={handleMultiSelectChange(setSourceApplication)}
                                    fullWidth
                                    style={{maxWidth: "100%"}}
                                >
                                    {availableSourcesApplication.map((source) => (
                                        <MenuItem key={source.id} value={source.id}>
                                            {source.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item>
                                <InputLabel>Γεωγραφία</InputLabel>
                                <Select
                                    multiple
                                    style={{maxWidth: "100%"}}
                                    value={geography.length === 0 ? ["none"] : geography}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        // If "Καμία" is currently selected and another option is selected, deselect "Καμία"
                                        if (value.includes("none") && value.length > 1) {
                                            return setGeography(value.filter((item) => item !== "none"));
                                        }

                                        // If another option is selected and "Καμία" is chosen, deselect the other options
                                        if (value.includes("none") && geography.length > 0) {
                                            return setGeography(["none"]);
                                        }

                                        setGeography(value);
                                    }}
                                    fullWidth
                                    renderValue={(selected) => {
                                        if (selected[0] === "none") {
                                            return "Καμία";
                                        }

                                        // Map the selected IDs to their geography names and corresponding carrier titles
                                        const selectedNames = selected.map((id) => {
                                            const geo = teamGeographies.find((geo) => geo.id === id);
                                            if (!geo) return undefined;

                                            const carrier = carriers.find((carrier) => carrier.id === geo.carrierId);
                                            return `${geo.name}${carrier ? ` (${carrier.title})` : ''}`;
                                        }).filter(Boolean);


                                        let result = selectedNames.slice(0, 3).join(", ");
                                        if (result.length > 100) {
                                            result = result.slice(0, 100) + "...";
                                        }

                                        if (selectedNames.length > 3) {
                                            result += `... +${selectedNames.length - 3} ακόμα`;
                                        }

                                        return result;
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="none">
                                        Καμία
                                    </MenuItem>
                                    {sortedTeamGeographies.map((geo) => {

                                        const carrier = carriers.find((c) => c.id === geo.carrierId);
                                        const carrierTitle = carrier ? carrier.title : '';

                                        return (
                                            <MenuItem key={geo.id} value={geo.id}>
                                                {`${geo.name}${carrierTitle ? ` (${carrierTitle})` : ''}`}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>


                            <Grid item>
                                <InputLabel>Μέθοδος Ανάθεσης</InputLabel>
                                <Select
                                    value={assignmentMethod}
                                    onChange={handleSelectChange(setAssignmentMethod)}
                                    fullWidth
                                    style={{maxWidth: "100%"}}
                                    disabled
                                >
                                    {teamAssignmentMethods.map((method) => (
                                        <MenuItem key={method.code} value={method.code}>
                                            {method.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item mb={1}>
                                <InputLabel>Διαθέσιμοι Ρόλοι</InputLabel>
                                <Select
                                    style={{maxWidth: "100%"}}
                                    value={groupRole || ""}
                                    onChange={handleSelectChange(setGroupRole)}
                                    fullWidth
                                    disabled
                                >
                                    {groupRoles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid>
                                <InputLabel style={{marginBottom: '10px'}}>Επιλεγμένοι Χρήστες</InputLabel>
                                {/* Search Selected Users Autocomplete */}
                                <Autocomplete
                                    id="search-selected-users"
                                    options={selectedUsers}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    value={highlightedUser}
                                    onChange={(event, newValue) => setHighlightedUser(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Αναζήτηση επιλεγμένων χρηστών"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {isUsersLoading ?
                                                            <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    style={{maxWidth: "100%", marginBottom: '10px'}}
                                    fullWidth
                                />

                                {/* Main Autocomplete */}
                                <Autocomplete
                                    multiple
                                    id="user-search"
                                    options={users}
                                    getOptionLabel={(option) => {
                                        const roleName = option.roleCollection[0]?.roleName;
                                        return `${option.id} ${option.firstName}  ${option.lastName} (${getRoleAbbreviation(roleName)})`;
                                    }}                                    filterSelectedOptions
                                    value={selectedUsers}
                                    onChange={(event, newValue) => setSelectedUsers(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                ref={(el) => (chipRefs.current[option.id] = el)}
                                                label={`${option.id} ${option.firstName} ${option.lastName} (${getRoleAbbreviation(option.roleCollection[0]?.roleName)})`}
                                                {...getTagProps({index})}
                                                style={
                                                    highlightedUser && highlightedUser.id === option.id
                                                        ? {backgroundColor: 'yellow', color: 'black'}
                                                        : {}
                                                }
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Προσθήκη ή αφαίρεση χρηστών"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {isUsersLoading ?
                                                            <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    PopperComponent={({style, ...props}) => (
                                        <Popper {...props}
                                                style={{...style, width: 'calc(80% - 16px)', maxHeight: 200}}/>
                                    )}
                                    style={{maxWidth: "100%", marginBottom: '10px'}}
                                    fullWidth
                                    disabled={isUsersLoading}
                                />


                            </Grid>
                            {isCarriersThematicsLoading ? (
                                <Grid item xs={12}>
                                    <LinearProgress
                                        style={{
                                            backgroundColor: '#e0e0e0', // Background color of the bar
                                        }}
                                        sx={{
                                            '& .MuiLinearProgress-barColorPrimary': {
                                                backgroundColor: '#003476', // Color of the progress indicator
                                            }
                                        }}
                                    />
                                </Grid>
                            ) : (
                                <Grid container spacing={4}>
                                    {carriersThematics.map((carrierThematic, index) => (
                                        <Grid item xs={12} md={carriersThematics.length === 1 ? 12 : 6} key={index}>
                                            <div>
                                                <p><strong>{carrierThematic.carrier.title} Θεματικές Κατηγορίες</strong>
                                                </p>

                                                {/* Thematics Dropdown */}

                                                <Select
                                                    multiple
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        // Exclude the "all" value when mapping over selected IDs
                                                        const actualSelected = selected.filter(id => id !== "all");

                                                        const selectedTitles = actualSelected.map(
                                                            (id) => carrierThematic.allThematics.find((thematic) => thematic.id === id)?.title
                                                        ).filter(Boolean);

                                                        let result = selectedTitles.slice(0, 3).join(", ");
                                                        if (result.length > 100) {
                                                            result = result.slice(0, 100);
                                                        }

                                                        if (selectedTitles.length > 3) {
                                                            result += "... + " + (selectedTitles.length - 3) + " ακόμα";
                                                        }

                                                        return result;
                                                    }}

                                                    style={{maxWidth: "100%", marginBottom: "16px"}}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    }}
                                                    value={carrierThematic.selectedThematics}
                                                    onChange={async (event) => {
                                                        let selectedThematicIDs = event.target.value;
                                                        if (selectedThematicIDs.includes("all")) {
                                                            selectedThematicIDs = carrierThematic.allThematics.map(
                                                                (thematic) => thematic.id
                                                            );
                                                        }

                                                        // Update the selected thematics
                                                        setCarriersThematics((prev) =>
                                                            prev.map((item) =>
                                                                item.carrier.id === carrierThematic.carrier.id
                                                                    ? {
                                                                        ...item,
                                                                        selectedThematics: selectedThematicIDs,
                                                                    }
                                                                    : item
                                                            )
                                                        );

                                                        //fetch the missing subthematics that they were not in the team
                                                        await fetchMissingSubthematicsForCarrier(carrierThematic, selectedThematicIDs);
                                                    }}
                                                >
                                                    <MenuItem value="all">Επιλογή Όλων</MenuItem>
                                                    {carrierThematic.allThematics
                                                        .filter(thematic => thematic && thematic.id !== undefined)
                                                        .map((thematic) => (
                                                            <MenuItem key={`thematic-${thematic.id}`}
                                                                      value={thematic.id}>
                                                                {thematic.title}
                                                            </MenuItem>
                                                        ))}
                                                </Select>


                                                {/* Subthematics Dropdown */}
                                                {carrierThematic?.selectedThematics.map((selectedThematicId) => {
                                                    const selectedThematic = carrierThematic.allThematics.find(t => t.id === selectedThematicId);
                                                    if (!selectedThematic) return null;

                                                    const subthematicsForSelectedThematic = carrierThematic.allSubThematics.filter(st => st.parentId === selectedThematicId);

                                                    return (
                                                        <React.Fragment key={selectedThematicId}>

                                                            <InputLabel>{selectedThematic.title} Θέματα</InputLabel>
                                                            <Select
                                                                style={{maxWidth: "100%", marginBottom: "16px"}}
                                                                multiple
                                                                renderValue={(selected) => {
                                                                    const selectedSubTitles = selected.map(id => {
                                                                        const foundSubthematic = carrierThematic.allSubThematics.find(subthematic => subthematic?.id === id);
                                                                        return foundSubthematic?.title;
                                                                    }).filter(Boolean);

                                                                    let result = selectedSubTitles.slice(0, 3).join(", ");
                                                                    if (result.length > 50) {
                                                                        result = result.slice(0, 50) + "...";
                                                                    }

                                                                    if (selectedSubTitles.length > 3) {
                                                                        result += ` +${selectedSubTitles.length - 3} ακόμα`;
                                                                    }
                                                                    return result;
                                                                }}
                                                                fullWidth
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: 200,
                                                                        },
                                                                    },
                                                                }}
                                                                value={carrierThematic.selectedSubthematics[selectedThematicId] || []}
                                                                onChange={(event) => {
                                                                    const updatedSubThematics = {
                                                                        ...carrierThematic.selectedSubthematics,
                                                                        [selectedThematicId]: event.target.value
                                                                    };
                                                                    setCarriersThematics((prev) =>
                                                                        prev.map((item) =>
                                                                            item.carrier.id === carrierThematic.carrier.id
                                                                                ? {
                                                                                    ...item,
                                                                                    selectedSubthematics: updatedSubThematics
                                                                                }
                                                                                : item
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                {subthematicsForSelectedThematic.map((subthematic) => (
                                                                    <MenuItem key={`subthematic-${subthematic.id}`}
                                                                              value={subthematic.id}>
                                                                        {subthematic.title}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                </div>
            )}
            <DialogActions>
                <Button onClick={handleClose} disabled={saveLoading}>
                    Κλείσιμο
                </Button>
                <Button onClick={handleSubmit} disabled={saveLoading}>
                    Αποθήκευση
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TeamEditDialog;
