import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { validateAFM, validateAMKA, validateMobile, validatePhone, validateSurname } from "app/main/Fields/ValidateFields";
import { searchBeneficiaries } from "app/Api Calls/FiltersList";
import SearchForm from "./SearchForm";
import BeneficiaryPagination from "./BeneficiaryPagination";
import BeneficiariesTable from "app/main/Panels/BeneficiaryPanel/BeneficiariesTabel";

function BeneficiaryInput() {
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState("");
    const [error, setError] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [noResultsMessage, setNoResultsMessage] = useState("");
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(100);

    useEffect(() => {
        if (searchClicked) {
            searchBeneficiariesHandler();
        }
    }, [page, size]);

    const isSearchDisabled = useMemo(() => {
        const helperText = getHelperText(search, searchType);
        return !search || !!helperText;
    }, [search, searchType, searchClicked]);

    function getHelperText(search, searchType) {
        if (!searchType) return "Επιλέξτε το κριτήριο αναζήτησης";
        if (!search) return "Το πεδίο είναι κενό";
        switch (searchType) {
            case "ΑΦΜ": return validateAFM(search);
            case "ΑΜΚΑ": return validateAMKA(search);
            case "Επώνυμο": return validateSurname(search);
            case "Τηλέφωνο": return validatePhone(search);
            case "Κινητό": return validateMobile(search);
            default: return "";
        }
    }

    const handleChange = (event) => {
        setSearch(event.target.value);
        setNoResultsMessage("");
        setError(getHelperText(event.target.value, searchType) !== "");
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setError(getHelperText(search, event.target.value) !== "");
        setNoResultsMessage("");
    };

    const searchBeneficiariesHandler = async () => {
        setError(false);
        setSearchClicked(true);
        setLoading(true);

        const result = await searchBeneficiaries(searchType, search, page, size);
        setLoading(false);

        if (result && result.elements.length > 0) {
            setBeneficiaries(result.elements);
            setTotalPages(result.totalPages);
            setTotalElements(result.totalElements);
        } else {
            setBeneficiaries([]);
            setTotalPages(0);
            setTotalElements(0);
            setNoResultsMessage(`Το φίλτρο αναζήτησης με ${searchType} ${search} δεν έφερε αποτελέσματα.`);
        }
    };

    const handlePageChange = (newPage) => {
        if (!isNaN(newPage) && newPage < totalPages) {
            setPage(newPage);
        }
    };

    const handleRowsPerPageChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        if (!isNaN(newSize)) {
            setSize(newSize);
            setPage(0);
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <SearchForm
                search={search}
                searchType={searchType}
                error={error}
                loading={loading}
                isSearchDisabled={isSearchDisabled}
                handleChange={handleChange}
                handleSearchTypeChange={handleSearchTypeChange}
                handleSearch={() => {
                    setPage(0);
                    searchBeneficiariesHandler();
                }}
                handleClearSearch={() => {
                    setSearch("");
                    setSearchType("");
                    setError(false);
                    setSearchClicked(false);
                    setBeneficiaries([]);
                    setPage(0);
                    setTotalPages(0);
                    setTotalElements(0);
                    setNoResultsMessage("");
                }}
                getHelperText={() => getHelperText(search, searchType)}
            />
            {noResultsMessage && (
                <Typography color="error" variant="body2" align="center" marginBottom={2}>
                    {noResultsMessage}
                </Typography>
            )}
            <BeneficiariesTable beneficiaries={beneficiaries} refreshData={searchBeneficiariesHandler} loading={loading} />
            <BeneficiaryPagination
                totalElements={totalElements}
                page={page}
                size={size}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
            />
        </Box>
    );
}

export default BeneficiaryInput;
