import { createSlice } from '@reduxjs/toolkit';

const editTicketSlice = createSlice({
  name: 'editTicketSlice',
  initialState: {
    comments: [],
    isCommentSubmitted: false,
    comment: '',
    isTicketFetched: false,
    areCommentsFetched: false,
    isUserInfoFetched: false,
    isCarrierAndThematicsFetched: false,
    editOriginalBeneficiary: [],
    editOriginalRepresentative: {
      id:null,
      afm: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      mobile: null
    },
    team: null,
    fetchedGeographyId: null,
    ticket: null,
    showCheckForChanges: false,
    selectedUserForDirectAssignment: null,
    selectedTeamForDirectAssignment: null,
    originalAsignee: null,
    shouldFetchFiles: false,
    allowNavigation: false,
    hasThematicsChanged: false,
    hasUnsavedChanges: false,
    beneficiaryChanges: false,
    representativeChanges: false,
    previousTicketVersion: 0,
    editMode: false,
    },
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setPreviousTicketVersion: (state, action) => {
      state.previousTicketVersion = action.payload;
    },
    setHasThematicsChanged: (state, action) => {
      state.hasThematicsChanged = action.payload;
    },
    setBeneficiaryChanges: (state, action) => {
      state.beneficiaryChanges = action.payload;
    },
    setRepresentativeChanges: (state, action) => {
      state.representativeChanges = action.payload;
    },
    setEditOriginalBeneficiary: (state, action) => {
        state.editOriginalBeneficiary = action.payload;
    },
    setEditOriginalRepresentative: (state, action) => {
      state.editOriginalRepresentative = action.payload;
    },

    setFetchedGeographyId: (state, action) => {
      state.fetchedGeographyId = action.payload;
    },
    setHasUnsavedChanges: (state, action) => {
      state.hasUnsavedChanges = action.payload;
    },
    setSelectedUserForDirectAssignment: (state, action) => {
      state.selectedUserForDirectAssignment = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setOriginalAsignee: (state, action) => {
      state.originalAsignee = action.payload;
    },
    setComment: (state, action) => {
      state.comment= action.payload;
    },
    setAllowNavigation: (state, action) => {
      state.allowNavigation= action.payload;
    },
    setIsCommentSubmitted: (state, action) => {
      state.isCommentSubmitted = action.payload;
    },
    setIsTicketFetched: (state, action) => {
      state.isTicketFetched = action.payload;
    },
    setAreCommentsFetched: (state, action) => {  
      state.areCommentsFetched = action.payload;
    },
    setIsUserInfoFetched: (state, action) => {  
      state.isUserInfoFetched = action.payload;
    },
    setShowCheckForChanges: (state, action) => {
      state.showCheckForChanges= action.payload;
    },
    setIsCarrierAndThematicsFetched: (state, action) => {  
      state.isCarrierAndThematicsFetched = action.payload;
    },
    setTicket: (state, action) => {
      state.ticket = action.payload;
    },
    setSelectedTeamForDirectAssignment: (state, action) => {
      state.selectedTeamForDirectAssignment = action.payload;
    },
    setShouldFetchFiles: (state, action) => {
      state.shouldFetchFiles = action.payload;
  },
    resetEditOriginalRepresentative: (state) => {
      state.editOriginalRepresentative = {
        id: null,
        afm: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        mobile: null
      };
    },
    resetEditTicketFlags: (state) => {
      state.isCommentSubmitted = false;
      state.isTicketFetched = false;
      state.areCommentsFetched = false;
      state.isUserInfoFetched = false;
      state.isCarrierAndThematicsFetched = false;
    },
  },
});

export const {setEditMode,resetEditOriginalRepresentative,setEditOriginalRepresentative,setPreviousTicketVersion,setBeneficiaryChanges,setRepresentativeChanges, setEditOriginalBeneficiary,setHasUnsavedChanges,setHasThematicsChanged,setAllowNavigation,setShouldFetchFiles,setTicket,setOriginalAsignee,setSelectedTeamForDirectAssignment,setSelectedUserForDirectAssignment,setShowCheckForChanges,setComments, setComment, setIsCommentSubmitted,setIsTicketFetched , setAreCommentsFetched,setIsUserInfoFetched,setIsCarrierAndThematicsFetched,resetEditTicketFlags,setTeam,setFetchedGeographyId} = editTicketSlice.actions;

export default editTicketSlice.reducer;
