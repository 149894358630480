// VideoCallParentIdModal.jsx
import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    CircularProgress,
    Alert,
    Pagination
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import RantebouTicketItem from "app/main/Rantebou/RantebouTicketItem";
import { setVideoCallParentId } from "store/ticketCreateSlice";

const VideoCallParentIdModal = ({ open, onClose, onSelectParent }) => {
    const dispatch = useDispatch();
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 10; // Adjust as needed

    // Get the currently selected video call parent ticket from Redux.
    const videoCallParentId = useSelector((state) => state.ticketSlice.videoCallParentId);
    const beneficiaryId = useSelector(state => state.userInfoSlice.beneficiaryId);

    const fetchTicketsData = async (page = 0) => {
        setLoading(true);
        setError('');
        const requestBody = {
            title: null,
            fromDate: null,
            toDate: null,
            ticketFlg: 701,
            priorityFlg: null,
            representativeFlg: null,
            followUpFlg: null,
            applicationFlg: null,
            beneficiaryId: beneficiaryId,
            representativeId: null,
            thematicId: null,
            ticketStatuses: [],
            assigneeUserId: null,
            uuid: null,
            groupId: null,
            whoIsCreated: null,
            callerId: null,
            carrierId: null,
            geographyId: null,
            ticketResolutionId: null,
            teams: [],
            sourceApplicationId: null,
            orderDto: [
                {
                    orderByColumn: "createdAt",
                    ordrerOrientation: "desc"
                }
            ],
            fromGroupRoleId: null,
        };
        const additionalParams = { limits: false };

        try {
            const data = await searchTickets(page, pageSize, requestBody, additionalParams);
            setTickets(data.elements || []);
            setCurrentPage(data.currentPage || 0);
            setTotalPages(data.totalPages || 0);
        } catch (err) {
            console.error("Error fetching parent tickets", err);
            setError("Αποτυχία φόρτωσης parent tickets.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch tickets when dialog opens or when page changes.
    useEffect(() => {
        if (open) {
            fetchTicketsData(0);
        }
    }, [open]);

    const handlePageChange = (event, value) => {
        // Assuming API pages are zero-indexed.
        fetchTicketsData(value - 1);
    };

    const handleSelectTicket = (ticket) => {
        // Dispatch the videoCallParentId action with id and uuid.
        dispatch(setVideoCallParentId({
            id: ticket.id ?? null,
            uuid: ticket.uuid ?? null,
        }));
        // Optionally, call the provided callback.
        if (onSelectParent) {
            onSelectParent(ticket);
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"  // Larger dialog width
            PaperProps={{ sx: { backgroundColor: '#edf0f6' } }}
        >
            <DialogTitle>Επιλογή Ticket Βίντεο Κλήσης</DialogTitle>
            <DialogContent>
                {loading && (
                    <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                {error && <Alert severity="error">{error}</Alert>}
                {!loading && !error && tickets.length === 0 && (
                    <Box sx={{ my: 2 }}>
                        <Alert severity="info">Δεν βρέθηκαν parent tickets.</Alert>
                    </Box>
                )}
                {tickets.map((ticket) => (
                    <Box
                        key={ticket.id}
                        sx={{
                            mb: 1,
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #ccc',
                            borderRadius: 1,
                            ...(videoCallParentId?.id === ticket.id && {
                                border: '2px solid blue',
                            }),
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: '100px',
                                height: '40px',
                            }}
                            onClick={() => {
                                if (videoCallParentId?.id === ticket.id) {
                                    // Unselect the ticket
                                    dispatch(setVideoCallParentId({ id: null, uuid: null }));
                                } else {
                                    handleSelectTicket(ticket);
                                }
                            }}
                        >
                            {videoCallParentId?.id === ticket.id ? "Επιλεγμένο" : "Επιλογή"}
                        </Button>

                        <Box sx={{ flexGrow: 1, width: '90%' }}>
                            <RantebouTicketItem ticket={ticket} hideAppointmentButton={true} />
                        </Box>
                    </Box>
                ))}
                {totalPages > 1 && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage + 1}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Κλείσιμο</Button>
            </DialogActions>
        </Dialog>
    );
};

export default VideoCallParentIdModal;
