import userInfoSlice, {
    changeName,
    changeSurname,
    changeAfm,
    changePhone,
    changeMobile,
    updateBeneficiaryData,
    validateErotisiText,
    changeRepresentativeFirstName,
    changeRepresentativeLastName,
    changeRepresentativeEmail,
    changeRepresentativePhone,
    changeRepresentativeMobile,
    changeRepresentativeAfm,
    changeEmail,
    changeAddress,
    changeAmka, changeProfession, changeSpecialInformation,
} from './userInfoSlice';
import {
    setSelectedCarrier,
    setSelectedThematic,
    setSelectedSubthematic,
    setSelectedSubSubthematic,
    fetchDataFromSubthematic, setActiveFlags,
} from 'store/knowledgeBasePageSlice';
import {setSelectedResolution, setMandatoryFields} from './resolutionsSlice';
import {setCallerId, setIsCarrierOPEKA, setSelectedGeography, setVideoCallParentId} from './ticketCreateSlice';
import {setFollowupTicketId} from "store/historyTicketSlice";

// Middleware
const mandatoryFieldsMiddleware = (storeAPI) => (next) => (action) => {
    const result = next(action);

    const userInfoActionTypes = [
        changeName.type,
        changeSurname.type,
        changeAfm.type,
        changePhone.type,
        changeMobile.type,
        changeProfession.type,
        changeSpecialInformation.type,
        validateErotisiText.type,
        updateBeneficiaryData.type,
        changeRepresentativeFirstName.type,
        changeRepresentativeLastName.type,
        changeRepresentativeEmail.type,
        changeRepresentativePhone.type,
        changeRepresentativeMobile.type,
        changeRepresentativeAfm.type,
        changeEmail.type,
        changeAddress.type,
        changeAmka.type,
        validateErotisiText.type,
        updateBeneficiaryData.type,
    ];

    const knowledgeBaseActionTypes = [
        setSelectedCarrier.type,
        setSelectedThematic.type,
        fetchDataFromSubthematic.fulfilled,
        setSelectedSubSubthematic.type,
        setActiveFlags.type
    ];

    const ticketActionTypes = [
        setCallerId.type,
        setSelectedGeography.type
    ];

    const ticketFlagTypes = [
        setFollowupTicketId.type,
        setVideoCallParentId.type
    ]

    const resolutionsActionTypes = [
        setSelectedResolution.type,
    ];

    if (action.type === setSelectedCarrier.type) {
        const selectedCarrier = action.payload;
        if (selectedCarrier && selectedCarrier.label === 'ΟΠΕΚΑ') {
            storeAPI.dispatch(setIsCarrierOPEKA(true));
        } else {
            storeAPI.dispatch(setIsCarrierOPEKA(false));
            storeAPI.dispatch(setSelectedGeography(null));
        }
    }

    if (
        userInfoActionTypes.includes(action.type) ||
        knowledgeBaseActionTypes.includes(action.type) ||
        resolutionsActionTypes.includes(action.type) ||
        ticketActionTypes.includes(action.type) ||
        ticketFlagTypes.includes(action.type)
    ) {
        const state = storeAPI.getState();
        // Check if the selected resolution is being set to null
        if (action.type === setSelectedResolution.type && action.payload === null) {
            // If the selected resolution is being set to null, do not re-evaluate mandatory fields
            return result;
        }

        setTimeout(() => {
            const state = storeAPI.getState();
            const userInfo = state.userInfoSlice;
            const followupTicketId = state.historyTicketSlice.followupTicketId;
            const videoCallParentId = state.ticketSlice.videoCallParentId;
            const knowledgeBasePage = state.knowledgeBasePageSlice;
            const ticket = state.ticketSlice;
            const resolutions = state.resolutionsSlice;
            const selectedResolution = resolutions.selectedResolution;
            const editMode = state.editTicketSlice.editMode;
            let mandatoryFieldsSet = new Set();

            if (userInfoActionTypes.includes(action.type) || ticketActionTypes.includes(action.type)) {
                const userInfoResults = computeUserInfoMandatoryFields(resolutions.mandatoryFields, userInfo, ticket, knowledgeBasePage,editMode);
                userInfoResults.forEach(field => mandatoryFieldsSet.add(JSON.stringify(field)));
            }

            if (knowledgeBaseActionTypes.includes(action.type)|| ticketFlagTypes.includes(action.type)) {
                const knowledgeBaseResults = computeKnowledgeBaseMandatoryFields(resolutions.mandatoryFields, knowledgeBasePage, ticket, action.type,followupTicketId, videoCallParentId );
                knowledgeBaseResults.forEach(field => mandatoryFieldsSet.add(JSON.stringify(field)));
            }

            if (resolutionsActionTypes.includes(action.type) || knowledgeBaseActionTypes.includes(action.type) || userInfoActionTypes.includes(action.type)|| ticketFlagTypes.includes(action.type)) {
                const resolutionResults = computeResolutionMandatoryFields(resolutions.mandatoryFields, userInfo, knowledgeBasePage, selectedResolution, ticket,followupTicketId,editMode, videoCallParentId );
                resolutionResults.forEach(field => mandatoryFieldsSet.add(JSON.stringify(field)));
            }

            const mandatoryFields = [...mandatoryFieldsSet].map(fieldStr => JSON.parse(fieldStr));
            storeAPI.dispatch(setMandatoryFields(mandatoryFields));
        }, 0);
    }
    return result;
};

const computeUserInfoMandatoryFields = (mandatoryFields, userInfo, ticket, knowledgeBasePage, editMode) => {
    const representativeFilled = userInfo.representativeAfm?.trim() !== "" ||
        userInfo.representativeFirstName?.trim() !== "" ||
        userInfo.representativeLastName?.trim() !== "" ||
        userInfo.representativeEmail?.trim() !== "" ||
        userInfo.representativePhone?.trim() !== "" ||
        userInfo.representativeMobile?.trim() !== "";
    const isFieldFilled = fieldValue => fieldValue !== null && fieldValue !== "";
    const phoneFilled = isFieldFilled(userInfo.phone);
    const mobileFilled = isFieldFilled(userInfo.mobile);
    const phoneValid = userInfo.isPhoneValid;
    const mobileValid = userInfo.isMobileValid;
    const representativePhoneFilled = isFieldFilled(userInfo.representativePhone);
    const representativeMobileFilled = isFieldFilled(userInfo.representativeMobile);
    const representativePhoneValid = userInfo.isRepresentativePhoneValid;
    const representativeMobileValid = userInfo.isRepresentativeMobileValid;

    return mandatoryFields.map((field) => {
        switch (field.title) {
            case 'FirstName':
                return {...field, filled: userInfo.isFirstnameValid};
            case 'LastName':
                return {...field, filled: userInfo.isSurnameValid};
            case 'TaxID':
                return {...field, filled: userInfo.isAfmValid};
            case 'Address':
                if (isFieldFilled(userInfo.address)) {
                    return {...field, filled: userInfo.isAddressValid};
                }
                break;
            case 'AMKA':
                if (isFieldFilled(userInfo.amka)) {
                    return {...field, filled: userInfo.isAmkaValid};
                }
                break;
            case 'Erotisi':
                // Exclude Erotisi field if editMode is true
                if (!editMode) {
                    return { ...field, filled: userInfo.isErotisiValid };
                }
                break;
            case 'CallerId':
                return {...field, filled: ticket.isCallerIdValid};
            case 'Geography':
                const isCarrierOPEKA = knowledgeBasePage.selectedCarrier && knowledgeBasePage.selectedCarrier.label === "ΟΠΕΚΑ";
                return {
                    ...field,
                    filled: (isCarrierOPEKA && (ticket.selectedGeography && Object.keys(ticket.selectedGeography).length !== 0)) || !isCarrierOPEKA,
                };
            case 'Phone':
                return {
                    ...field,
                    filled: (phoneFilled && phoneValid) || (!phoneFilled && mobileValid)
                };
            case 'Mobile':
                return {
                    ...field,
                    filled: (mobileFilled && mobileValid) || (!mobileFilled && phoneValid)
                };
            case 'Profession':
                if (isFieldFilled(userInfo.profession)) {
                    return {...field, filled: userInfo.profession.length >= 3};
                }
                break;
            case 'SpecialInformation':
                if (isFieldFilled(userInfo.specialInformation)) {
                    return {...field, filled: userInfo.specialInformation.length >= 3};
                }
                break;

            case 'RepresentativeTaxID':
                return representativeFilled ? {...field, filled: userInfo.isRepresentativeAfmValid} : null;
            case 'RepresentativeFirstName':
                return representativeFilled ? {...field, filled: userInfo.isRepresentativeFirstNameValid} : null;
            case 'RepresentativeLastName':
                return representativeFilled ? {...field, filled: userInfo.isRepresentativeLastNameValid} : null;
            case 'RepresentativeEmail':
                return representativeFilled ? {...field, filled: userInfo.isRepresentativeEmailValid} : null;
            case 'RepresentativePhone':
                return representativeFilled ? {
                    ...field,
                    filled: (representativePhoneFilled && representativePhoneValid) || (!representativePhoneFilled && representativeMobileValid)
                } : null;
            case 'RepresentativeMobile':
                return representativeFilled ? {
                    ...field,
                    filled: (representativePhoneFilled && representativePhoneValid) || (!representativePhoneFilled && representativeMobileValid)
                } : null;

            default:
                return field;
        }
    }).filter(field => field);
};

const computeKnowledgeBaseMandatoryFields = (mandatoryFields, knowledgeBasePage, ticket, actionType,followupTicketId,videoCallParentId) => {
    const isFollowUpActive = knowledgeBasePage.activeFlags.some(flag => flag.code === 200 && flag.name === "Follow up");
    const isVideoCallActive = knowledgeBasePage.activeFlags.some(flag => flag.code === 702);

    return mandatoryFields.map((field) => {
        switch (field.title) {
            case 'Carrier':
                return {
                    ...field,
                    filled: !!knowledgeBasePage.selectedCarrier
                };
            case 'ThematicUnit':
                return {
                    ...field,
                    filled: !!knowledgeBasePage.selectedThematic
                };
            case 'Topic/Issue':
                if (actionType === fetchDataFromSubthematic.fulfilled || actionType === setSelectedSubSubthematic.type || actionType === setSelectedSubthematic.type) {
                    return {
                        ...field,
                        filled: (knowledgeBasePage.selectedSubthematic && knowledgeBasePage.subSubthematics.length === 0) ||
                            knowledgeBasePage.selectedSubSubthematic
                    };
                }
                return field;
            case 'Geography':
                if (knowledgeBasePage.selectedCarrier &&
                    knowledgeBasePage.selectedCarrier.label === "ΟΠΕΚΑ") {
                    return {
                        ...field,
                        filled: ticket.selectedGeography !== null && Object.keys(ticket.selectedGeography).length !== 0
                    };
                } else {
                    return {
                        ...field,
                        filled: true
                    };
                }
                return field;
            case 'FollowUp':
                if (isFollowUpActive) {
                    return {
                        ...field,
                        filled: followupTicketId && followupTicketId.id !== null && followupTicketId.uuid !== null,
                    };
                } else {
                    return {
                        ...field,
                        filled: true
                    };
                }
            case 'VideoCall':
                // Always return a field object. If not active, mark as false.
                return {
                    ...field,
                    filled: isVideoCallActive
                        ? (videoCallParentId && videoCallParentId.id !== null && videoCallParentId.uuid !== null)
                        : true,
                };

            default:
                return field;
        }
    });
};

const computeResolutionMandatoryFields = (mandatoryFields, userInfo, knowledgeBasePage, selectedResolution, ticket, followupTicketId, editMode,videoCallParentId) => {
    if (!selectedResolution) {
        return [];
    }
    const representativeFilled = userInfo.representativeAfm?.trim() !== "" ||
        userInfo.representativeFirstName?.trim() !== "" ||
        userInfo.representativeLastName?.trim() !== "" ||
        userInfo.representativeEmail?.trim() !== "" ||
        userInfo.representativePhone?.trim() !== "" ||
        userInfo.representativeMobile?.trim() !== "";

    const representativePhoneOrMobileFilled = userInfo.isRepresentativePhoneValid ? "Phone" : userInfo.isRepresentativeMobileValid ? "Mobile" : null;
    const representativeEmailFilled = userInfo.representativeEmail !== null && userInfo.representativeEmail?.trim() !== "";
    const isFieldFilled = fieldValue => fieldValue !== null && fieldValue !== "";
    const representativePhoneFilled = isFieldFilled(userInfo?.representativePhone);
    const representativeMobileFilled = isFieldFilled(userInfo?.representativeMobile);
    const representativePhoneValid = userInfo.isRepresentativePhoneValid;
    const representativeMobileValid = userInfo.isRepresentativeMobileValid;

    let userFields = [
        {greekLabel: "Όνομα", title: "FirstName", filled: userInfo.isFirstnameValid},
        {greekLabel: "Επώνυμο", title: "LastName", filled: userInfo.isSurnameValid},
        {greekLabel: "ΑΦΜ", title: "TaxID", filled: userInfo.isAfmValid},
    ];

    const phoneFilled = isFieldFilled(userInfo.phone);
    const mobileFilled = isFieldFilled(userInfo.mobile);
    const phoneValid = userInfo.isPhoneValid;
    const mobileValid = userInfo.isMobileValid;

    userFields.push({
        greekLabel: "Τηλέφωνο",
        title: "Phone",
        filled: phoneValid || (mobileValid && !phoneFilled)
    });

    if (mobileValid || (phoneValid && mobileFilled)) {
        userFields.push({greekLabel: "Κινητό", title: "Mobile", filled: mobileValid});
    } else if (mobileFilled) {
        userFields.push({greekLabel: "Κινητό", title: "Mobile", filled: false});
    }

    if (isFieldFilled(userInfo.amka)) {
        userFields.push({greekLabel: "ΑΜΚΑ", title: "AMKA", filled: userInfo.isAmkaValid});
    }
    if (isFieldFilled(userInfo.address)) {
        userFields.push({greekLabel: "Διεύθυνση", title: "Address", filled: userInfo.isAddressValid});
    }
    if (isFieldFilled(userInfo.email)) {
        userFields.push({greekLabel: "Email", title: "Email", filled: userInfo.isEmailValid});
    }

    if (isFieldFilled(userInfo.profession)) {
        userFields.push({greekLabel: "Επάγγελμα", title: "Profession", filled: userInfo.profession.length >= 3});
    }
    if (isFieldFilled(userInfo.specialInformation)) {
        userFields.push({greekLabel: "Ειδικές Πληροφορίες", title: "SpecialInformation", filled: userInfo.specialInformation?.length >= 3});
    }

    // Check if "Follow up" flag is active
    const isFollowUpActive = knowledgeBasePage.activeFlags.some(flag => flag.code === 200 && flag.name === "Follow up");
    const isVideoCallActive = knowledgeBasePage.activeFlags.some(flag => flag.code === 702);

    const commonFields = [
        {
            greekLabel: "Φορέας",
            title: "Carrier",
            filled: !!knowledgeBasePage.selectedCarrier,
        },
        {
            greekLabel: "Θεματική Ενότητα",
            title: "ThematicUnit",
            filled: !!knowledgeBasePage.selectedThematic,
        },
        {
            greekLabel: "Θέμα / Υποθέμα",
            title: "Topic/Issue",
            filled: !!((knowledgeBasePage.selectedSubthematic && knowledgeBasePage.subSubthematics.length === 0) || knowledgeBasePage.selectedSubSubthematic),
        },
    ];

    let erotisiField = null;
    if (!editMode) {
        erotisiField = {
            greekLabel: "Ερώτηση",
            title: "Erotisi",
            filled: userInfo.isErotisiValid,
        };
    }

    let geographyField = {
        greekLabel: "Γεωγραφία",
        title: "Geography",
        filled: knowledgeBasePage.selectedCarrier && knowledgeBasePage.selectedCarrier.label === "ΟΠΕΚΑ"
            ? (ticket.selectedGeography !== null && Object.keys(ticket.selectedGeography).length !== 0)
            : true
    };

    let followUpField = {
        greekLabel: "Follow up",
        title: "FollowUp",
        filled: isFollowUpActive ? (followupTicketId && followupTicketId.id !== null && followupTicketId.uuid !== null) : true
    };

    let videoCallField = {
        greekLabel: "Ticket Βίντεο Κλήσης",
        title: "VideoCall",
        filled: isVideoCallActive
            ? (videoCallParentId && videoCallParentId.id !== null && videoCallParentId.uuid !== null)
            : true,
    };

    let fields = [];
    if (["Wrong Number", "Line Failure", "Reopen"].includes(selectedResolution.labelEn)) {
        // Mark the Carrier, ThematicUnit, and Topic/Issue as filled true
        fields = [
            {
                greekLabel: "Φορέας",
                title: "Carrier",
                filled: true,
            },
            {
                greekLabel: "Θεματική Ενότητα",
                title: "ThematicUnit",
                filled: true,
            },
            {
                greekLabel: "Θέμα / Υποθέμα",
                title: "Topic/Issue",
                filled: true,
            },
        ];

    } else if (["General Information", "Draft"].includes(selectedResolution.labelEn)) {
        fields = [...commonFields];
        if (erotisiField) fields.push(erotisiField);
        fields.push({
            greekLabel: "Εισερχόμενο Τηλέφωνο",
            title: "CallerId",
            filled: !!ticket.isCallerIdValid,
        }, followUpField, videoCallField);

    } else if (["Personalized Information", "E-Services Guidance", "Appointment Closed"].includes(selectedResolution.labelEn)) {
        fields = [
            ...commonFields,
            {
                greekLabel: "Όνομα",
                title: "FirstName",
                filled: userInfo.isFirstnameValid,
            },
            {
                greekLabel: "Επώνυμο",
                title: "LastName",
                filled: userInfo.isSurnameValid,
            },
            {
                greekLabel: "ΑΦΜ",
                title: "TaxID",
                filled: userInfo.isAfmValid,
            },
            {
                greekLabel: "Τηλέφωνο",
                title: "Phone",
                filled: userInfo.isPhoneValid || userInfo.isMobileValid,
            },
            ...userFields,
            ...(erotisiField ? [erotisiField] : []),
            {
                greekLabel: "Εισερχόμενο Τηλέφωνο",
                title: "CallerId",
                filled: !!ticket.isCallerIdValid,
            },
            followUpField,
            videoCallField,
            ...(representativeFilled ? [
                {
                    greekLabel: "Αντιπρόσωπος ΑΦΜ",
                    title: "RepresentativeTaxID",
                    filled: userInfo.isRepresentativeAfmValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Όνομα",
                    title: "RepresentativeFirstName",
                    filled: userInfo.isRepresentativeFirstNameValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Επώνυμο",
                    title: "RepresentativeLastName",
                    filled: userInfo.isRepresentativeLastNameValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Τηλέφωνο",
                    title: "RepresentativePhone",
                    filled: (representativePhoneFilled && representativePhoneValid) || (!representativePhoneFilled && representativeMobileValid)
                },
                {
                    greekLabel: "Αντιπρόσωπος Κινητό",
                    title: "RepresentativeMobile",
                    filled: (representativeMobileFilled && representativeMobileValid) || (!representativeMobileFilled && representativePhoneValid)
                },
            ] : []),
        ];

    } else if (["Move to SA", "Move to FO", "Move to BO"].includes(selectedResolution.labelEn)) {
        fields = [
            ...commonFields,
            ...userFields,
            ...(erotisiField ? [erotisiField] : []),
            geographyField,
            {
                greekLabel: "Εισερχόμενο Τηλέφωνο",
                title: "CallerId",
                filled: !!ticket.isCallerIdValid,
            },
            followUpField,
            videoCallField,
            ...(representativeFilled ? [
                {
                    greekLabel: "Αντιπρόσωπος ΑΦΜ",
                    title: "RepresentativeTaxID",
                    filled: userInfo.isRepresentativeAfmValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Όνομα",
                    title: "RepresentativeFirstName",
                    filled: userInfo.isRepresentativeFirstNameValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Επώνυμο",
                    title: "RepresentativeLastName",
                    filled: userInfo.isRepresentativeLastNameValid
                },
                {
                    greekLabel: "Αντιπρόσωπος Τηλέφωνο",
                    title: "RepresentativePhone",
                    filled: (representativePhoneFilled && representativePhoneValid) || (!representativePhoneFilled && representativeMobileValid)
                },
                {
                    greekLabel: "Αντιπρόσωπος Κινητό",
                    title: "RepresentativeMobile",
                    filled: (representativeMobileFilled && representativeMobileValid) || (!representativeMobileFilled && representativePhoneValid)
                },
            ] : []),
        ];

    } else if (selectedResolution.labelEn === "Callback") {
        fields = [
            ...commonFields,
            {
                greekLabel: "Όνομα",
                title: "FirstName",
                filled: userInfo.isFirstnameValid,
            },
            {
                greekLabel: "Επώνυμο",
                title: "LastName",
                filled: userInfo.isSurnameValid,
            },
            {
                greekLabel: "ΑΦΜ",
                title: "TaxID",
                filled: userInfo.isAfmValid,
            },
            {
                greekLabel: "Τηλέφωνο",
                title: "Phone",
                filled: userInfo.isPhoneValid || userInfo.isMobileValid,
            },
            ...userFields,
            {
                greekLabel: "Εισερχόμενο Τηλέφωνο",
                title: "CallerId",
                filled: ticket.isCallerIdValid ? true : false,
            }
        ];
    }

    const representativeFields =
        userInfo.representativeAfm ||
        userInfo.representativeFirstName ||
        userInfo.representativeLastName
            ? [
                {
                    greekLabel: "Αντιπρόσωπος ΑΦΜ",
                    title: "RepresentativeTaxID",
                    filled: userInfo.isRepresentativeAfmValid,
                },
                {
                    greekLabel: "Αντιπρόσωπος Όνομα",
                    title: "RepresentativeFirstName",
                    filled: userInfo.isRepresentativeFirstNameValid,
                },
                {
                    greekLabel: "Αντιπρόσωπος Επώνυμο",
                    title: "RepresentativeLastName",
                    filled: userInfo.isRepresentativeLastNameValid,
                },
                {
                    greekLabel: "Αντιπρόσωπος Τηλέφωνο",
                    title: "RepresentativePhone",
                    filled: (representativePhoneFilled && representativePhoneValid) || (!representativePhoneFilled && representativeMobileValid)
                },
                {
                    greekLabel: "Αντιπρόσωπος Κινητό",
                    title: "RepresentativeMobile",
                    filled: (representativeMobileFilled && representativeMobileValid) || (!representativeMobileFilled && representativePhoneValid)
                },
            ]
            : [];
    if (representativeFilled && representativeEmailFilled) {
        representativeFields.push({
            greekLabel: "Αντιπρόσωπος Email",
            title: "RepresentativeEmail",
            filled: userInfo.isRepresentativeEmailValid
        });
    }
    return [...fields, ...representativeFields];
};


export default mandatoryFieldsMiddleware;
