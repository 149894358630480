import { axiosApi } from "app/configs/axiosConfig";

export const checkBeneficiaryExistsByAfm = async (afm) => {
    try {
        const response = await axiosApi.get(`/beneficiary/checkBeneficiaryExistsByAfm/${afm}`);
        return response?.data?.found || false; // Return `true` if found, otherwise `false`
    } catch (error) {
        throw error;
    }
};
