import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Pagination,
    Button,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import RantebouTicketItem from './RantebouTicketItem';
import el from 'date-fns/locale/el'; // Greek locale

const RantebouPage = () => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const size = 10; // Number of tickets per page

    const fetchTicketsData = async (page = 0) => {
        setLoading(true);
        setError('');
        try {
            const requestBody = {
                title: null,
                fromDate: null,
                toDate: null,
                ticketFlg: 701,
                priorityFlg: null,
                representativeFlg: null,
                followUpFlg: null,
                applicationFlg: null,
                beneficiaryId: null,
                representativeId: null,
                thematicId: null,
                ticketStatuses: [],
                assigneeUserId: null,
                uuid: null,
                groupId: null,
                whoIsCreated: null,
                callerId: null,
                carrierId: null,
                geographyId: null,
                ticketResolutionId: null,
                teams: [],
                sourceApplicationId: null,
                orderDto: [
                    {
                        orderByColumn: "createdAt",
                        ordrerOrientation: "desc"
                    }
                ],
                fromGroupRoleId: null,
            };

            // If both dates are provided, set fromDate and toDate accordingly.
            if (startDate && endDate) {
                const fromDateObj = new Date(startDate);
                fromDateObj.setHours(0, 0, 0, 0);
                const toDateObj = new Date(endDate);
                toDateObj.setHours(23, 59, 59, 999);
                requestBody.fromDate = fromDateObj.toISOString();
                requestBody.toDate = toDateObj.toISOString();
            }

            const additionalParams = { limits: false };
            const data = await searchTickets(page, size, requestBody, additionalParams);
            setTickets(data.elements || []);
            setCurrentPage(data.currentPage || 0);
            setTotalPages(data.totalPages || 0);
        } catch (err) {
            console.error("Error fetching tickets", err);
            setError("Failed to load tickets.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch on mount.
    useEffect(() => {
        fetchTicketsData();
    }, []);

    // When both dates are cleared, re-fetch without date filters.
    useEffect(() => {
        if (startDate === null && endDate === null) {
            fetchTicketsData(0);
        }
    }, [startDate, endDate]);

    const handlePageChange = (event, value) => {
        fetchTicketsData(value - 1);
    };

    // Allow search only if both dates are selected.
    const disableSearch = !(startDate && endDate);

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Ραντεβού Βίντεο Κλήσεων
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
                <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <DatePicker
                        label="Από ημερομηνία"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                        }}
                    />
                    <DatePicker
                        label="Έως ημερομηνία"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => fetchTicketsData(0)}
                        disabled={disableSearch}
                    >
                        Αναζήτηση
                    </Button>
                    {(startDate || endDate) && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleClear}
                        >
                            Καθαρισμός
                        </Button>
                    )}
                </Box>
            </LocalizationProvider>
            <Box position="relative">
                {tickets.length === 0 && !loading && !error && (
                    <Typography>Δεν βρέθηκαν tickets.</Typography>
                )}
                {tickets.map((ticket, index) => (
                    <RantebouTicketItem
                        key={ticket.id || index}
                        ticket={ticket}
                        onAppointmentUpdate={fetchTicketsData}
                    />
                ))}
                {loading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor="rgba(255, 255, 255, 0.8)"
                        zIndex={999}
                        sx={{ pointerEvents: 'none' }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            {totalPages > 1 && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage + 1}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}
        </Box>
    );
};

export default RantebouPage;
