import axios from 'axios';
import { jwtService } from 'app/auth/jwtService';
import jwtServiceConfig from 'app/auth/jwtServiceConfig';
import { v4 as uuidv4 } from 'uuid';

// Create an instance of axios that will not have interceptors
const axiosNoInterceptors = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://staging.ccticketing.eu/ticketingApi/',
});

// Create the main axios instance
const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://staging.ccticketing.eu/ticketingApi/',
});

// Retrieve user information once and store it
const user = (() => {
  const user = window.localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
})();

const uniqueUUID = (() => {
  let uuid = localStorage.getItem('login_uuid');
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem('login_uuid', uuid);
  }
  return uuid;
})();



axiosApi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwt_access_token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (user && user.id) {
        config.headers['X-USER-ID'] = user.id;
      }

      config.headers['X-BROWSER-CONNECT'] = uniqueUUID;


      return config;
    },
    (error) => Promise.reject(error)
);

// Function to handle global logout on 401 error
const handle401Error = (error) => {
  // Check if the URL includes 'sign-in'
  if (error.config && error.config.url.includes('public/login')) {
    // Custom message for sign-in failures
    jwtService.logout("Λάθος Username ή Password");
  } else {
    // Default logout message
    jwtService.logout("Αποσυνδεθήκατε");
  }
};

// Add a response interceptor
let isTokenBeingRenewed = false;
let requestsQueue = [];

// Function to process the queue
const processQueue = (error, token = null) => {
  requestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  requestsQueue = [];
  isTokenBeingRenewed = false;
};

// Add a response interceptor to handle token refresh logic
axiosApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('jwt_refresh_token');

      if (error.response?.status === 401 && refreshToken && !originalRequest._retry) {
        if (!isTokenBeingRenewed) {
          isTokenBeingRenewed = true;
          originalRequest._retry = true;

          try {
            const { data } = await axiosNoInterceptors.post(jwtServiceConfig.renewToken, {
              refreshAccessToken: refreshToken,
            });

            localStorage.setItem('jwt_access_token', data.accessToken);
            localStorage.setItem('jwt_refresh_token', data.refreshAccessToken);
            processQueue(null, data.accessToken);

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            return axiosApi(originalRequest);
          } catch (renewError) {
            processQueue(renewError, null);
            handle401Error(renewError);
            return Promise.reject(renewError);
          } finally {
            isTokenBeingRenewed = false;
          }
        } else {
          return new Promise((resolve, reject) => {
            requestsQueue.push({
              resolve: (newToken) => {
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                resolve(axiosApi(originalRequest));
              },
              reject: (error) => {
                reject(error);
              },
            });
          });
        }
      } else if (error.response?.status === 401) {
        handle401Error(error);
      }
      return Promise.reject(error);
    }
);

export { axiosApi, axiosNoInterceptors };
