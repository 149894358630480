/* eslint-disable class-methods-use-this */
import axios from "axios";
import {EventEmitter} from 'events';
import {jwtDecode} from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";
import {axiosApi} from "app/configs/axiosConfig";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import {getUserTeamsAndCarriers, searchTeams} from "app/Api Calls/SearchTeams";
import {getUserCarriers} from "app/Api Calls/GetUserCarriers";
import store from "store/index";

import { v4 as uuidv4 } from 'uuid';
import settingsConfig from "app/configs/settingConfig";
import {setAssignment} from "store/ticketFetchSlice";
import {fetchAllTeams} from "app/Api Calls/TeamsRequiredApis";
/* eslint-disable camelcase */

class JwtService extends EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    if (
                        err &&
                        err.response &&
                        err.response.status === 401 &&
                        err.config &&
                        !err.config.__isRetryRequest
                    ) {
                        this.emit("onAutoLogout", "Λάθος Username ή Password");
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    handleAuthentication() {
        const access_token = this.getAccessToken();
        const refresh_token = localStorage.getItem("jwt_refresh_token");

        if (!access_token || !refresh_token) {
            this.emit("onNoAccessToken");
            return;
        }

        if (
            this.isAuthTokenValid(access_token) &&
            this.isRefreshTokenValid(refresh_token)
        ) {
            this.setSession(access_token, refresh_token);
            this.emit("onAutoLogin", true);
        } else {
            this.signInWithToken()
                .then((userData) => {
                    const newToken = this.getAccessToken();
                    const newRefreshToken = localStorage.getItem("jwt_refresh_token");
                    this.setSession(newToken, newRefreshToken, JSON.stringify(userData));
                    this.emit("onAutoLogin", true);
                })
                .catch((error) => {
                    console.error("Error renewing access token:", error);
                    this.setSession(null);
                    this.emit("onAutoLogout", "Πρέπει να ξανασυνδεθείτε");
                });
        }
    }

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post(jwtServiceConfig.signUp, data).then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.token);
                    resolve(response.data.user);
                    this.emit("onLogin", response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };


// Sign in with email and password method
    // Sign in with email and password method
    signInWithEmailAndPassword = async (username, password) => {
        try {
            const response = await axiosApi.post(jwtServiceConfig.signIn, {
                username,
                password,
            });

            const token = response.data.accessToken;
            const refreshToken = response.data.refreshAccessToken;
            const decodedToken = jwtDecode(token);

            if (decodedToken) {
                let user = {
                    username: decodedToken.userDetails.username,
                    email: decodedToken.userDetails.email,
                    roleName: decodedToken.userDetails.roles[0].roleName,
                    id: decodedToken.userDetails.id,
                };
                const uuid = uuidv4();
                localStorage.setItem('login_uuid', uuid);

                // Set the session early with basic user details and tokens to make sure API calls are authenticated
                this.setSession(token, refreshToken);

                // Fetch additional user details, carriers, and team information using the new API
                let teams = [];
                let carriersDto = [];
                const additionalDetails = await this.fetchAndStoreUserDetails(user.id);

                if (!settingsConfig.roles.admin.includes(user.roleName)) {
                    const userCarriersAndTeams = await getUserTeamsAndCarriers();
                    teams = userCarriersAndTeams.map(team => ({
                        id: team.id,
                        name: team.name,
                        groupRole: team.groupRole,
                    }));
                     carriersDto = Array.from(
                        new Map(
                            userCarriersAndTeams
                                .flatMap(team => team.carriersDto)
                                .map(carrier => [carrier.id, carrier])
                        ).values()
                    );
                }

                user = {
                    ...user,
                    ...additionalDetails,
                    teams,
                    carriers: carriersDto,
                };

                localStorage.setItem("user", JSON.stringify(user));
                const userInitialState = this.getUserInitialState(user.roleName);
                store.dispatch(setAssignment(userInitialState));
                this.emit("onLogin", user);
                return user;
            } else {
                throw new Error("Token decoding failed");
            }
        } catch (error) {
            console.error("Error during sign in with email and password:", error);

            // Extract and throw custom errors based on the response status
            let errorMessage = "An error occurred";
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        errorMessage = "Λάθος Username ή Password";
                        break;
                    case 403:
                        errorMessage = "CORS issue or access forbidden";
                        break;
                    case 406:
                        errorMessage = "Είσοδος μόνο με κωδικούς Taxis";
                        break;
                    default:
                        break;
                }
            }
            throw new Error(errorMessage);
        }
    };



// Sign in with token method

    signInWithToken = async () => {
        const token = this.getAccessToken();
        const refreshToken = localStorage.getItem("jwt_refresh_token");

        // Check if token and refreshToken are valid before proceeding
        if (!token || !this.isAuthTokenValid(token) || !this.isRefreshTokenValid(refreshToken)) {
            throw new Error("Invalid or expired token.");
        }

        try {
            // Decoding the token and getting user data
            const decodedToken = jwtDecode(token);
            let user = this.getUser();

            // Assuming user data is part of the decoded token
            if (decodedToken && user) {
                user = {
                    ...user,
                    username: decodedToken.userDetails.username,
                    email: decodedToken.userDetails.email,
                    roleName: decodedToken.userDetails.roles[0].roleName,
                    id: decodedToken.userDetails.id,
                };

                // Fetch additional user details
                let teams = [];
                let carriersDto = [];
                const additionalDetails = await this.fetchAndStoreUserDetails(user.id);

                if (!settingsConfig.roles.admin.includes(user.roleName)) {
                    const userCarriersAndTeams = await getUserTeamsAndCarriers();
                    teams = userCarriersAndTeams.map(team => ({
                        id: team.id,
                        name: team.name,
                        groupRole: team.groupRole,
                    }));
                     carriersDto = Array.from(
                        new Map(
                            userCarriersAndTeams
                                .flatMap(team => team.carriersDto)
                                .map(carrier => [carrier.id, carrier])
                        ).values()
                    );
                }

                user = {
                    ...user,
                    ...additionalDetails,
                    teams,
                    carriers: carriersDto,
                };
                this.setSession(token, refreshToken, JSON.stringify(user));
                localStorage.setItem("user", JSON.stringify(user));
                const userInitialState = this.getUserInitialState(user.roleName);
                store.dispatch(setAssignment(userInitialState));
                return user;
            } else {
                // Handle token renewal
                const response = await axiosApi.post(jwtServiceConfig.renewToken, {
                    accessToken: token,
                    refreshAccessToken: refreshToken,
                    type: "Bearer",
                });

                if (response.status === 200) {
                    const newToken = response.data.accessToken;
                    const decodedNewToken = jwtDecode(newToken);

                    user = {
                        username: decodedNewToken.userDetails.username,
                        email: decodedNewToken.userDetails.email,
                        roleName: decodedNewToken.userDetails.roles[0].roleName,
                        id: decodedNewToken.userDetails.id,
                    };

                    // Fetch additional user details
                    const additionalDetails = await this.fetchAndStoreUserDetails(user.id);
                    const userCarriersAndTeams = await getUserTeamsAndCarriers();

                    // Extract teams and carriers from the response
                    const teams = userCarriersAndTeams.map(team => ({
                        id: team.id,
                        name: team.name,
                    }));
                    const carriersDto = Array.from(
                        new Map(
                            userCarriersAndTeams
                                .flatMap(team => team.carriersDto)
                                .map(carrier => [carrier.id, carrier])
                        ).values()
                    );

                    user = { ...user, ...additionalDetails, teams, carriers: carriersDto };
                    this.setSession(newToken, refreshToken, JSON.stringify(user));
                    localStorage.setItem("user", JSON.stringify(user));
                    const userInitialState = this.getUserInitialState(user.roleName);
                    store.dispatch(setAssignment(userInitialState));
                    return user;
                } else {
                    throw new Error('Token renewal failed. Logging out...');
                }
            }
        } catch (error) {
            console.error("Error during sign in with token:", error);
            throw new Error('Error signing in with token.');
        }
    };


// Fetch and store user details method
    async fetchAndStoreUserDetails(userId) {
        try {
            const user = await getSpecificUser(userId);
            if (user && user.roleCollection && user.roleCollection.length > 0) {
                const updatedUserData = {
                    userGroupId: user.roleCollection[0].group.id,
                    userRoleId: user.roleCollection[0].id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                };
                return updatedUserData;
            }
            return {};
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            throw new Error('Failed to fetch user details');
        }
    }

// Sign in with Taxis method
    signInWithTaxis = async (access_token, refresh_token) => {
        try {
            // Immediately decode the token to verify its structure and validity
            const decodedToken = jwtDecode(access_token);

            // Validate both the access and refresh tokens
            if (!this.isAuthTokenValid(access_token) || !this.isRefreshTokenValid(refresh_token)) {
                throw new Error("Tokens are invalid");
            }

            // Assuming the decoded token contains the necessary user details
            if (decodedToken) {
                let user = {
                    username: decodedToken.userDetails.username,
                    email: decodedToken.userDetails.email,
                    roleName: decodedToken.userDetails.roles[0].roleName,
                    id: decodedToken.userDetails.id,
                };
                const uuid = uuidv4();
                localStorage.setItem('login_uuid', uuid);
                // Set the session with the user details and tokens
                this.setSession(access_token, refresh_token, JSON.stringify(user));

                // Fetch additional user details, carriers, and teams
                let teams = [];
                let carriersDto = [];
                const additionalDetails = await this.fetchAndStoreUserDetails(user.id);

                if (!settingsConfig.roles.admin.includes(user.roleName)) {
                    const userCarriersAndTeams = await getUserTeamsAndCarriers();
                    teams = userCarriersAndTeams.map(team => ({
                        id: team.id,
                        name: team.name,
                        groupRole: team.groupRole,
                    }));
                     carriersDto = Array.from(
                        new Map(
                            userCarriersAndTeams
                                .flatMap(team => team.carriersDto)
                                .map(carrier => [carrier.id, carrier])
                        ).values()
                    );

                }

                user = {
                    ...user,
                    ...additionalDetails,
                    teams,
                    carriers: carriersDto,
                };
                localStorage.setItem("user", JSON.stringify(user));

                // Emit an event for successful login
                this.emit("onLogin", user);

                // Resolve the function with the user object
                return user;
            } else {
                throw new Error("Token decoding failed");
            }
        } catch (error) {
            console.error("Error during sign in with Taxis:", error);
            throw error; // Rethrow the error to be handled by the caller
        }
    };



    setSession = (access_token, refresh_token, userString) => {
        if (access_token) {
            // User is logging in
            localStorage.setItem("jwt_access_token", access_token);
            if (refresh_token) {
                localStorage.setItem("jwt_refresh_token", refresh_token);
            }
            if (userString) {
                localStorage.setItem("user", userString);
            }
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            localStorage.setItem("auth-status", "authenticated");
        } else {
            // User is logging out
            localStorage.removeItem("jwt_access_token");
            localStorage.removeItem("jwt_refresh_token");
            localStorage.removeItem("user");
            localStorage.removeItem('login_uuid');
            delete axios.defaults.headers.common.Authorization;
            localStorage.removeItem("auth-status");
            localStorage.setItem("logout-event", "logged-out");
            setTimeout(() => localStorage.removeItem("logout-event"), 500);
        }
    };


    logout = (message) => {
        this.setSession(null, null, null);
        localStorage.setItem("logout-event", "logged-out");
        this.emit("onLogout", message);
        setTimeout(() => localStorage.removeItem("logout-event"), 500);
    };

    isAuthTokenValid = (access_token) => {
        try {
            const decoded = jwtDecode(access_token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.warn("access token expired");
                return false;
            }
            return true;
        } catch (err) {
            console.warn("Invalid access token");
            return false;
        }
    };

    isRefreshTokenValid = (refresh_token) => {
        try {
            const decoded = jwtDecode(refresh_token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.warn("refresh token expired");
                return false;
            }
            return true;
        } catch (err) {
            console.warn("Invalid refresh token");
            return false;
        }
    };


    getAccessToken = () => {
        return window.localStorage.getItem("jwt_access_token");
    };

    getUser = () => {
        const user = window.localStorage.getItem("user");
        return user ? JSON.parse(user) : null;
    };

    // Utility function to calculate initial state based on user role
    getUserInitialState = (userRole) => {
        let assignment = {
            assignToMe: false,
            assignToTeam: false,
        };

        if (settingsConfig.roles.agent.includes(userRole)) {
            assignment.assignToMe = true;
            assignment.assignToTeam = false;
        } else if (settingsConfig.roles.teamLeader.includes(userRole)) {
            assignment.assignToTeam = true;
            assignment.assignToMe = false;
        } else if (settingsConfig.roles.supervisor.includes(userRole)) {
            assignment.assignToMe = false;
            assignment.assignToTeam = false;
        }
        return assignment;
    };

}


export const jwtService = new JwtService();

