import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Stack,
    Typography,
    Box
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import elLocale from 'date-fns/locale/el';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
import { fetchChildTicketsByParentId } from 'app/Api Calls/FollowUpIds';
import { showMessage } from 'store/messageSlice';
import { useDispatch } from 'react-redux';

const AppointmentDialog = ({ open, onClose, onSelectAppointment, ticketId, ticketUuid }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [confirmStep, setConfirmStep] = useState(false);
    const dispatch = useDispatch();

    // When the dialog opens, fetch child tickets for the given ticketId.
    useEffect(() => {
        if (open && ticketId) {
            fetchChildTicketsByParentId(ticketId)
                .then((data) => {
                    // If data is an empty array, no child tickets exist.
                    if (Array.isArray(data) && data.length !== 0) {
                        onClose();
                        dispatch(showMessage({
                            message: `Δεν μπορείτε να αλλάξετε το ραντεβού για το ticket ${ticketUuid}`,
                            autoHideDuration: 6000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                            variant: 'error',
                        }));
                    }
                })
                .catch((error) => {
                    console.error('Error fetching child tickets', error);
                });
        }
    }, [open]);

    const handleReset = () => {
        setConfirmStep(false);
    };

    const handleConfirm = () => {
        if (selectedDate && selectedTime) {
            setConfirmStep(true);
        }
    };

    // Format the combined date and time to Greek local format.
    const formatCombined = () => {
        const combined = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            selectedTime.getHours(),
            selectedTime.getMinutes()
        );
        return dayjs(combined).format('DD/MM/YYYY HH:mm');
    };

    const handleFinalConfirm = () => {
        const combined = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            selectedTime.getHours(),
            selectedTime.getMinutes()
        );
        const utcISO = dayjs(combined).utc().toISOString();

        onSelectAppointment(utcISO);
        onClose();
        // Reset internal state.
        setSelectedDate(null);
        setSelectedTime(null);
        setConfirmStep(false);
    };

    // Disable weekends (Saturday:6, Sunday:0)
    const shouldDisableDate = (date) => {
        return date.getDay() === 0 || date.getDay() === 6;
    };

    // Restrict DatePicker to only allow dates from today onward.
    const minDate = dayjs().startOf('day').toDate();

    // Restrict time picker between 09:00 and 17:00.
    // If the selected date is today, use the later of current time or 09:00.
    const computeMinTime = () => {
        const nineAM = dayjs().hour(9).minute(0).second(0).millisecond(0);
        if (selectedDate && dayjs(selectedDate).isSame(dayjs(), 'day')) {
            let now = dayjs();
            const remainder = now.minute() % 30;
            if (remainder !== 0) {
                now = now.add(30 - remainder, 'minute');
            }
            return now.isAfter(nineAM) ? now.toDate() : nineAM.toDate();
        }
        return nineAM.toDate();
    };

    const maxTime = dayjs().hour(17).minute(0).second(0).millisecond(0).toDate();

    // New function to clear selections
    const handleClearSelections = () => {
        setSelectedDate(null);
        setSelectedTime(null);
        setConfirmStep(false);
    };

    // Determine if any selection has been made
    const isAnySelectionMade = selectedDate || selectedTime;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={elLocale}>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Επιλογή Ημερομηνίας και Ώρας Ραντεβού</DialogTitle>
                <DialogContent>
                    {!confirmStep ? (
                        <>
                            <Stack spacing={2} sx={{ mt: 1 }}>
                                <DatePicker
                                    label="Επιλέξτε Ημερομηνία"
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue)}
                                    format="dd/MM/yyyy"
                                    shouldDisableDate={shouldDisableDate}
                                    minDate={minDate}
                                    // Disable typing by passing `readOnly: true` to the textField
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            inputProps: { readOnly: true },
                                        },
                                    }}
                                />
                                <TimePicker
                                    label="Επιλέξτε Ώρα"
                                    value={selectedTime}
                                    onChange={(newValue) => setSelectedTime(newValue)}
                                    ampm={false}  // Use 24-hour format
                                    minutesStep={30}
                                    minTime={computeMinTime()}
                                    maxTime={maxTime}
                                    shouldDisableTime={(timeValue, clockType) => {
                                        if (clockType === 'hours' && selectedDate && dayjs(selectedDate).isSame(dayjs(), 'day')) {
                                            const currentHour = dayjs().hour();
                                            return timeValue < currentHour;
                                        }
                                        return false;
                                    }}
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            inputProps: { readOnly: true },
                                        },
                                    }}
                                />

                            </Stack>
                            {isAnySelectionMade && (
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleClearSelections}
                                        sx={{
                                            backgroundColor: '#d32f2f',
                                            '&:hover': { backgroundColor: '#b71c1c' },
                                        }}
                                    >
                                        Αφαίρεση Επιλογών
                                    </Button>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" align="center">
                                Είστε σίγουροι για το ραντεβού;
                            </Typography>
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{ fontWeight: 'bold', mt: 1 }}
                            >
                                {formatCombined()}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    {!confirmStep ? (
                        <>
                            <Button onClick={onClose} variant="contained">
                                Ακύρωση
                            </Button>
                            <Button
                                onClick={handleConfirm}
                                variant="contained"
                                disabled={
                                    !selectedDate ||
                                    !selectedTime ||
                                    new Date(
                                        selectedDate.getFullYear(),
                                        selectedDate.getMonth(),
                                        selectedDate.getDate(),
                                        selectedTime.getHours(),
                                        selectedTime.getMinutes()
                                    ) < new Date()
                                }
                            >
                                Επιβεβαίωση
                            </Button>

                        </>
                    ) : (
                        <>
                            <Button onClick={handleReset} variant="contained">
                                Όχι
                            </Button>
                            <Button onClick={handleFinalConfirm} variant="contained">
                                Ναι
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default AppointmentDialog;
