import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  CircularProgress,
  Chip,
  Avatar,
  Tooltip,
  Tabs, Tab, AppBar
} from "@mui/material";
import fetchTicketById from "app/Api Calls/FetchTicketById";
import fetchBeneficiaryById from "app/Api Calls/FetchBeneficiaryById";
import fetchRepresentativeById from "app/Api Calls/GetRepresentativeById";
import {
  fetchCarrierAndThenSetThematic,
  resetKnowledgeBase
} from "store/knowledgeBasePageSlice";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import {resetOriginalBeneficiaryData, resetUserForm, setBeneficiaryId} from "store/userInfoSlice";
import {clearTicketsData, setFollowupTicketId} from "store/historyTicketSlice";
import {fetchTeamById} from "app/Api Calls/GetTeamById";
import IstorikoTicket from "../create-ticket/IstorikoTicket";
import TicketHistoryDetails from "../TicketHistoryDetails";
import TicketComments from "../TicketComments";
import {getGeographyById} from "app/Api Calls/GetGeographyByCarrierId";
import getFlagColor from "app/main/Reusable Components/Ticket Reusable/FlagsColors";
import {clearBeneficiaryLastSearchParams, clearFetchedBeneficiaries} from "store/beneficiaryFetchSlice";
import {fetchChildTicketsByParentId, fetchParentTicketById} from "app/Api Calls/FollowUpIds";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ButtonBase from "@mui/material/ButtonBase";

function TicketDetails() {
  const [assigneeUser, setAssigneeUser] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);
  const [representative, setRepresentative] = useState(null);
  const [geography, setGeography] = useState(null);
  const [team, setTeam] = useState(null);
  const [comment, setComment] = useState("");
  const [parentTickets, setParentTickets] = useState([]);
  const [childTickets, setChildTickets] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const {id} = useParams();
  const {
    selectedCarrier,
    selectedThematic,
    selectedSubthematic,
    selectedSubSubthematic,
  } = useSelector((state) => state.knowledgeBasePageSlice);
  const statuses = useSelector((state) => state.ticketSlice.statuses);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTicket = async () => {
      try {

        const fetchedTicket = await fetchTicketById(id);
        if (fetchedTicket) {
          setTicket(fetchedTicket);
          await fetchParentAndChildTickets(fetchedTicket);
        }

        if (fetchedTicket?.beneficiaryId) {
          const fetchedBeneficiary = await fetchBeneficiaryById(
              fetchedTicket?.beneficiaryId
          );
          setBeneficiary(fetchedBeneficiary);
          dispatch(setBeneficiaryId(fetchedTicket?.beneficiaryId));
        }

        if (fetchedTicket?.geographyId) {
          const fetchedGeography = await getGeographyById(fetchedTicket?.geographyId);
          setGeography(fetchedGeography);
        }

        if (fetchedTicket?.representativeId) {
          const fetchedRepresentative = await fetchRepresentativeById(
              fetchedTicket?.representativeId
          );
          setRepresentative(fetchedRepresentative);
        }

        if (fetchedTicket?.geographyId) {
          const fetchedGeography = await getGeographyById(fetchedTicket?.geographyId);
          setGeography(fetchedGeography);
        }

        if (fetchedTicket?.thematicId) {
          dispatch(fetchCarrierAndThenSetThematic(fetchedTicket?.thematicId));
        }

        if (fetchedTicket?.assigneeUserId) {
          const fetchedAssigneeUser = await getSpecificUser(
              fetchedTicket?.assigneeUserId
          );
          setAssigneeUser(fetchedAssigneeUser);
        }

        if (fetchedTicket && fetchedTicket?.teamId) {
          async function fetchTeamDetails() {
            try {
              const fetchedTeam = await fetchTeamById(fetchedTicket?.teamId);
              setTeam(fetchedTeam);
            } catch (error) {
              console.error('Error fetching team details:', error);
            }
          }

          fetchTeamDetails();
        }
      } catch (error) {
        if (error.response?.status === 401) {
          // Log the 401 error
          console.log('Unauthorized access (401):', error);
        } else {
          // Handle other errors
          console.error('Error fetching ticket details:', error);
        }
      }
    };

    fetchTicket();

    const cleanup = () => {
      dispatch(resetKnowledgeBase());
      dispatch(resetUserForm());
      dispatch(resetOriginalBeneficiaryData());
      dispatch(clearBeneficiaryLastSearchParams());
      dispatch(clearFetchedBeneficiaries());
      dispatch(setBeneficiaryId(null));
      dispatch(clearTicketsData());
      dispatch(setFollowupTicketId(null));
      setParentTickets([]);
      setChildTickets([]);
      setActiveTab(0);
    };

    return cleanup;
  }, [id, dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  // Fetch parent and child tickets
  const fetchParentAndChildTickets = async (ticket) => {
    const { id: ticketId, hasParent, hasChild } = ticket;

    // Initialize containers for parent and child tickets
    const parentTickets = [];
    const childTickets = [];

    // Fetch parent tickets if the ticket has a parent
    if (hasParent) {
      try {
        const parentData = await fetchParentTicketById(ticketId);
        if (Array.isArray(parentData)) {
          parentTickets.push(...parentData);
        } else if (parentData?.id) {
          parentTickets.push(parentData);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn(`No parent tickets found for ticket ID: ${ticketId}`);
        } else {
          console.error("Error fetching parent tickets:", error.message);
        }
      }
    }

    // Fetch child tickets if the ticket has children
    if (hasChild) {
      try {
        const childData = await fetchChildTicketsByParentId(ticketId);
        if (Array.isArray(childData)) {
          childTickets.push(...childData);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn(`No child tickets found for ticket ID: ${ticketId}`);
        } else {
          console.error("Error fetching child tickets:", error.message);
        }
      }
    }

    // Update state after fetching
    setParentTickets(parentTickets);
    setChildTickets(childTickets);
  };



  if (!ticket) {
    return (
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
        >
          <CircularProgress/>
        </Box>
    );
  }

  const handleBackToTicketList = () => {
    navigate("/tickets");
  };

  const handleNavigateToTicket = (ticketId) => {
    navigate(`/ticket/${ticketId}`);
  };

  // Find the status based on the ticket's statusId
  const ticketStatus = statuses.find(
      (status) => status.id === ticket.ticketStatusId
  );
  const formattedDate = new Intl.DateTimeFormat("el-GR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(ticket.createdAt));

  return (
      <div>
        <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
            <Tab label="Στοιχεία Ticket" />
            <Tab label="CRM" />
          </Tabs>
        </AppBar>
        <Button
            onClick={handleBackToTicketList}
            className="bg-[#003476] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-8"
        >
          Πίσω στην Λίστα
        </Button>

        {activeTab === 0 && (
            <div>
              <Box sx={{ p: 0.5, mx: 'auto', display: 'flex', gap: '1rem' }} />
              <TableContainer sx={{ maxHeight: 'calc(100vh - 10rem)', maxWidth: 'lg', mx: 'auto' }}>
                <Card sx={{ maxHeight: '80vh', overflowY: 'auto', position: 'relative' }}>
                  <Box
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 2,
                        padding: '16px 16px 0px 16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        maxWidth: '100%',
                      }}
                  >
                    {/* Ticket UUID and Child Tickets */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1, flexWrap: 'wrap', maxWidth: '100%' }}>
                      <Typography variant="h4">{ticket.uuid}</Typography>
                      {childTickets.length > 0 && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', maxWidth: '100%' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center' }}>Follow up σε:</Typography>
                            {childTickets.map((child) => (
                                <ButtonBase
                                    key={child.id}
                                    onClick={() => handleNavigateToTicket(child.id)}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      backgroundColor: '#4caf50 !important',
                                      color: 'white',
                                      padding: '8px 12px',
                                      borderRadius: '8px',
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                      '&:hover': {
                                        backgroundColor: '#388e3c',
                                      },
                                      maxWidth: '100%',
                                    }}
                                >
                                  <Typography variant="body2">{child.uuid}</Typography>
                                  <ArrowForwardIcon sx={{ color: 'white' }} />
                                </ButtonBase>
                            ))}
                          </Box>
                      )}
                    </Box>

                    {/* Parent Tickets */}
                    {parentTickets.length > 0 && (
                        <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              flexWrap: 'wrap',
                              maxWidth: '100%',
                              mt: childTickets.length > 2 ? 2 : 0,  
                            }}
                        >
                          <Typography variant="h6" sx={{ textAlign: 'center' }}>Follow Up από:</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', maxWidth: '100%' }}>
                            {parentTickets.map((parent) => (
                                <ButtonBase
                                    key={parent.id}
                                    onClick={() => handleNavigateToTicket(parent.id)}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      backgroundColor: '#f97316 !important',
                                      color: 'white',
                                      padding: '8px 12px',
                                      borderRadius: '8px',
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                      '&:hover': {
                                        backgroundColor: '#fb8c00',
                                      },
                                      maxWidth: '100%',
                                    }}
                                >
                                  <Typography variant="body2">{parent.uuid}</Typography>
                                  <ArrowForwardIcon sx={{ color: 'white' }} />
                                </ButtonBase>
                            ))}
                          </Box>
                        </Box>
                    )}
                  </Box>



                  <CardContent>
                    <Typography variant="subtitle1" color="text.secondary">
                      Ημερομηνία: {formattedDate}
                    </Typography>

                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                      <Box component="span" fontWeight="fontWeightBold">
                        Εισερχόμενο Τηλέφωνο:
                      </Box>{" "}
                      {ticket.callerId === -1 ? "Απόκρυψη αριθμού" : ticket.callerId}
                    </Typography>

                    <Typography variant="body2">
                      <Box component="span" fontWeight="fontWeightBold">
                        Φορέας:
                      </Box>{" "}
                      {selectedCarrier?.label || "N/A"} -{">"}
                      <Box component="span" fontWeight="fontWeightBold">
                        Θεματικές:
                      </Box>{" "}
                      {selectedThematic?.label || ""}
                      {selectedThematic?.label && selectedSubthematic?.label
                          ? " -> "
                          : ""}
                      {selectedSubthematic?.label || ""}
                      {selectedSubthematic?.label && selectedSubSubthematic?.label
                          ? " -> "
                          : ""}
                      {selectedSubSubthematic?.label || ""}
                      {geography && (
                          <p>
                            <Box component="span" fontWeight="fontWeightBold">
                              Γεωγραφία:
                            </Box> {geography?.name || ""}
                          </p>
                      )}
                    </Typography>

                    <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                      >
                        <Box sx={{ width: "49%" }}>
                          <Typography variant="h6">Κατάσταση Ticket</Typography>
                          {ticketStatus ? (
                              <>
                                <Typography variant="body2">
                                  <strong>Status:</strong> {ticketStatus.name}
                                </Typography>
                                {assigneeUser && (
                                    <Typography variant="body2" component="div">
                                      <strong>Ανατεθειμένο
                                        σε:</strong> {assigneeUser.firstName} {assigneeUser.lastName}
                                      {assigneeUser.roleCollection[0] && (
                                          <>
                                            {" - "}
                                            {assigneeUser.roleCollection[0].roleName
                                                .split("_")
                                                .slice(1)
                                                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                                                .join(" ")}
                                          </>
                                      )}
                                    </Typography>
                                )}
                              </>
                          ) : (
                              <Typography variant="body2">Δεν υπάρχει Ενεργή Κατάσταση</Typography>
                          )}
                          <Typography variant="body2" component="div">
                            <strong> Ομάδα: </strong>
                            {team && team.name ? team.name : "Δεν υπάρχει Ομάδα"}
                          </Typography>
                        </Box>

                        <Box sx={{ width: "49%" }}>
                          <Typography variant="h6">Flags</Typography>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                            {ticket.ticketFlagCollection && ticket.ticketFlagCollection.length > 0 ? (
                                <div style={{ display: "flex" }}>
                                  {ticket.ticketFlagCollection.map((flag) => {
                                    const { color, icon: Icon } = getFlagColor(flag.code);
                                    return (
                                        <Tooltip title={flag.name} key={flag.code}>
                                          <Avatar
                                              style={{
                                                backgroundColor: color,
                                                margin: "0 4px"
                                              }}
                                          >
                                            <Icon style={{ color: "white" }} />
                                          </Avatar>
                                        </Tooltip>
                                    );
                                  })}
                                </div>
                            ) : (
                                <Typography variant="body2">Χωρίς Flags</Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "2rem",
                          }}
                      >
                        <Box sx={{ width: "49%" }}>
                          <Typography variant="h6">Πληροφορίες Δικαιούχου</Typography>
                          {!beneficiary ? (
                              <Typography variant="body2">Δεν υπάρχει Δικαιούχος</Typography>
                          ) : (
                              <>
                                <Typography
                                    variant="body2">Όνομα: {beneficiary.firstName}</Typography>
                                <Typography
                                    variant="body2">Επώνυμο: {beneficiary.lastName}</Typography>
                                <Typography variant="body2">ΑΦΜ: {beneficiary.afm}</Typography>
                                <Typography variant="body2">AMKA: {beneficiary.amka}</Typography>
                                <Typography
                                    variant="body2">Σταθερό: {beneficiary.phone}</Typography>
                                <Typography
                                    variant="body2">Κινητό: {beneficiary.mobile}</Typography>
                                <Typography
                                    variant="body2">Διεύθυνση: {beneficiary.address}</Typography>
                                {beneficiary.profession && (
                                    <Typography variant="body2">Επάγγελμα: {beneficiary.profession}</Typography>
                                )}
                                {beneficiary.specialInformation && (
                                    <Typography variant="body2">Ειδικές Πληροφορίες: {beneficiary.specialInformation}</Typography>
                                )}
                              </>
                          )}
                        </Box>

                        <Box sx={{ width: "49%" }}>
                          <Typography variant="h6">Πληροφορίες Αντιπροσώπου</Typography>
                          {representative ? (
                              <>
                                <Typography variant="body2">ΑΦΜ: {representative.afm}</Typography>
                                <Typography
                                    variant="body2">Όνομα: {representative.firstName}</Typography>
                                <Typography
                                    variant="body2">Επώνυμο: {representative.lastName}</Typography>
                                <Typography
                                    variant="body2">Email: {representative.email}</Typography>
                                <Typography
                                    variant="body2">Τηλέφωνο: {representative.phone}</Typography>
                                <Typography
                                    variant="body2">Κινητό: {representative.mobile}</Typography>
                              </>
                          ) : (
                              <Typography variant="body2">Δεν υπάρχει αντιπρόσωπος</Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <TicketComments id={ticket.id} ticketDescription={ticket.description} />
                    <TicketHistoryDetails ticketId={ticket.id} />

                  </CardContent>
                </Card>
              </TableContainer>
            </div>
        )}

        {activeTab === 1 && <IstorikoTicket />}
      </div>
  );


}

export default TicketDetails;
