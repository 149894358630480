import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { searchTeams } from "app/Api Calls/SearchTeams";
import { fetchAllGroupRoles } from "app/Api Calls/FetchAllGroupRoles";
import { getSpecificUser } from "app/Api Calls/GetSpecificUser";
import { showMessage } from "store/messageSlice";
import assignTeamToTicket from "app/Api Calls/AssignTeamToTicket";
import { useDispatch } from "react-redux";
import fetchThematicById from "app/Api Calls/GetThematicById";
import { useSelector } from "react-redux";
import { setSelectedTeamForDirectAssignment, setShowCheckForChanges } from "store/editTicketSlice";
import { jwtService } from "app/auth/jwtService";
import CheckForChanges from "./CheckForChanges";

function GroupAssignment({
  thematicId,
  ticket,
  onUpdate,
}) {
  // State for opening and closing the dialog
  const [openModal, setOpenModal] = useState(false);
  const [teams, setTeams] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [groupRoles, setGroupRoles] = useState([]);
  const [thematics, setThematics] = useState([]);
  const [themas, setThemas] = useState([]);
  const [subthemas, setSubthemas] = useState([]);
  const [selectedThematic, setSelectedThematic] = useState(null);
  const [selectedThema, setSelectedThema] = useState(null);
  const [selectedSubthema, setSelectedSubthema] = useState(null);
  const [showSteppedSelects, setShowSteppedSelects] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [noParentThematics, setNoParentThematics] = useState(false);
  const dispatch = useDispatch();
  const selectedTeam = useSelector((state)=> state.editTicketSlice.selectedTeamForDirectAssignment);
  const showCheckForChanges = useSelector((state) => state.editTicketSlice.showCheckForChanges);

  useEffect(() => {
    
    if (selectedTeam && selectedTeam.thematicCollection) {

      // Populate thematics where parentId is null
      const filteredThematics = selectedTeam.thematicCollection.filter(
        (thematic) => thematic.parentId === null && thematic.isActive
      );
      setThematics(filteredThematics);

      // Set noParentThematics based on the length of filteredThematics
      setNoParentThematics(filteredThematics?.length === 0);

      // Populate themas based on selectedThematic
      if (selectedThematic) {
        const filteredThemas = selectedTeam.thematicCollection.filter(
          (thematic) => thematic.parentId === selectedThematic.id && thematic.isActive
        );
        setThemas(filteredThemas);
      } else if (noParentThematics) {
        // When there are no parent thematics, find themes that have a parentId not present in allThematicCollections

        // First, gather all id values from allThematicCollections
        const allIds = new Set(selectedTeam.thematicCollection.map(item => item.id));

        // Then, find themes whose parentId is not in the list of ids
        const themesForMissingThematics = selectedTeam.thematicCollection.filter(
            (item) => !allIds.has(item.parentId) && item.isActive
        );

        setThemas(themesForMissingThematics);

      } else {
        // Reset themas if no thematic is selected
        setThemas([]);
      }

      // Populate subthemas based on selectedThema
      if (selectedThema) {
        const filteredSubthemas = selectedTeam.thematicCollection.filter(
          (thematic) => thematic.parentId === selectedThema.id
        );
        setSubthemas(filteredSubthemas);
      } else {
        // Reset subthemas if no thema is selected
        setSubthemas([]);
      }
    }
  }, [selectedTeam, showSteppedSelects, selectedThematic, selectedThema, noParentThematics]);

  useEffect(() => {
    async function fetchUserDataAndRoles() {
      const userData = jwtService.getUser();
      const fetchedUserDetails = await getSpecificUser(userData.id);
      setUserDetails(fetchedUserDetails);

      const roles = await fetchAllGroupRoles();
      setGroupRoles(roles);
    }

    fetchUserDataAndRoles();
  }, []);

  useEffect(() => {
    async function fetchAllTeams() {
      setIsLoadingTeams(true);
      const groupRoleId = getUserGroupRoleId();

      if (!groupRoleId) {
        setIsLoadingTeams(false);
        return;
      }

      try {
        const thematic = await fetchThematicById(ticket.thematicId);

        // Ensure the thematic is valid and active before proceeding
        if (!thematic || !thematic.isActive) {
          console.warn(`Thematic ID ${ticket.thematicId} is inactive or not found.`);
          setIsLoadingTeams(false);
          return;
        }

        const { carrierId } = thematic;

        let currentPage = 0;
        const pageSize = 20;
        let totalTeams = [];

        while (true) {
          const fetchedTeams = await searchTeams(
              currentPage,
              pageSize,
              groupRoleId,
              carrierId
          );

          // Process teams and filter out inactive thematics
          let filteredTeams = fetchedTeams.elements.map(team => ({
            ...team,
            thematicCollection: team.thematicCollection.filter(t => t.isActive) // Keep only active thematics
          })).filter(team => team.thematicCollection.length > 0); // Ensure team has at least one active thematic

          // Find missing parent thematics
          const allThematicIds = new Set(filteredTeams.flatMap(team => team.thematicCollection.map(t => t.id)));
          const missingParentIds = [...new Set(
              filteredTeams.flatMap(team => team.thematicCollection
                  .filter(t => t.parentId !== null && !allThematicIds.has(t.parentId)) // Find thematics whose parent is missing
                  .map(t => t.parentId)
              )
          )];

          // Fetch and add missing parent thematics
          for (const parentId of missingParentIds) {
            const missingParentThematic = await fetchThematicById(parentId);
            if (missingParentThematic?.isActive) {
              filteredTeams = filteredTeams.map(team => ({
                ...team,
                thematicCollection: [...team.thematicCollection, missingParentThematic]
              }));
            }
          }

          totalTeams = [...totalTeams, ...filteredTeams];

          if (fetchedTeams.currentPage >= fetchedTeams.totalPages - 1) {
            break;
          }
          currentPage += 1;
        }

        // Sort teams alphabetically by name
        totalTeams.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        setTeams(totalTeams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        setIsLoadingTeams(false);
      }
    }

    fetchAllTeams();
  }, [userDetails, groupRoles, ticket.thematicId]);


  // const getFullTeamDetails = () => {
  //   return teams.find((team) => team.id === selectedTeam.id);
  // };

  function getUserGroupRoleId() {
    if (userDetails && userDetails.roleCollection) {
      const matchedRole = groupRoles.find((groupRole) =>
        userDetails.roleCollection.some(
          (userRole) => userRole.group && userRole.group.id === groupRole.id
        )
      );

      if (matchedRole) {
        return matchedRole.id;
      }
    }
    return null;
  }

  const handleSelectionChange = (e) => {
    const chosenTeam = teams.find((team) => team.id === e.target.value);
    dispatch(setSelectedTeamForDirectAssignment(chosenTeam));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    resetToInitialState();
    dispatch(setSelectedTeamForDirectAssignment(null));
    setOpenModal(false);
  };

  const showSuccessMessage = () => {
    dispatch(
      showMessage({
        message: `Η ανάθεση σε ομάδα έγινε επιτυχώς`,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "success",
      })
    );
  };

  const showErrorMessage = () => {
    dispatch(
      showMessage({
        message: `Υπήρξε πρόβλημα με την απευθείας ανάθεση στην Ομάδα`,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "error",
      })
    );
  };

  const resetToInitialState = () => {
    // dispatch(setSelectedTeamForDirectAssignment(null));
    setUserDetails(null);
    setGroupRoles([]);
    setThematics([]);
    setThemas([]);
    setSubthemas([]);
    setSelectedThematic(null);
    setSelectedThema(null);
    setSelectedSubthema(null);
    setShowSteppedSelects(false);
  };

  const handleConfirmAssignment = async () => {
    try {
      setIsLoading(true);

      if (isThematicIdInTeam(selectedTeam, thematicId)) {
        setOpenModal(false);
        dispatch(setShowCheckForChanges(true));
        
      } else {
        if (selectedSubthema) {
            setOpenModal(false);
            dispatch(setShowCheckForChanges(true));
          
          } 
         else {
          setShowSteppedSelects(true);
        }
      }
     

      setIsLoading(false);
    } catch (error) {
      console.error("Error during group assignment:", error);
      showErrorMessage();
      setIsLoading(false);
    }
  };

  const isThematicIdInTeam = (team, thematicId) => {
    if (!team || !team.thematicCollection) return false;

    return team.thematicCollection.some(
      (thematic) => thematic.id === thematicId
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>


      <FormControl
    variant="outlined"
    style={{
      width: "380px",
      marginRight: "10px",
      height: "42px",
      backgroundColor: "white",
    }}
  >
    <InputLabel htmlFor="group-select">Επιλογή Ομάδας</InputLabel>
    <Select
          label="Επιλογή Ομάδας"
          value={selectedTeam?.id || ""}
          onChange={handleSelectionChange}
          input={
            <OutlinedInput
              name="groupSelection"
              id="group-select"
              style={{
                height: "42px",
               
              }}
              startAdornment={
                isLoadingTeams && (
                  <InputAdornment position="start">
                    <CircularProgress
                      size={20}
                      style={{ marginRight: "10px" }}
                    />
                  </InputAdornment>
                )
              }
            />
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '300px',
              },
            },
          }}
        >
          {teams.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            width: "500px",
            minHeight: "200px",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                maxHeight: "500px",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <DialogTitle id="alert-dialog-title">
                {"Απευθείας Ανάθεση σε Ομάδα"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {!showSteppedSelects &&
                    `Είστε σίγουροι ότι θέλετε να κάνετε ανάθεση του ticket στην ${selectedTeam?.name}?`}{" "}
                </DialogContentText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {showSteppedSelects && (
                    <>
                      <Typography>Θα πρέπει να αλλάξετε θεματικές</Typography>

                      {!noParentThematics && thematics?.length > 0 && (
                        <>
                          <InputLabel style={{ marginTop: "10px" }}>
                            Θεματική Ενότητα
                          </InputLabel>
                          <Select
                            value={selectedThematic?.id || ""}
                            onChange={(e) =>
                              setSelectedThematic(
                                thematics.find((t) => t.id === e.target.value)
                              )
                            }
                          >
                            {thematics.map((thematic) => (
                              <MenuItem key={thematic.id} value={thematic.id}>
                                {thematic.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}

                      {themas?.length > 0 && (
                        <>
                          <InputLabel style={{ marginTop: "10px" }}>
                            Θέμα
                          </InputLabel>
                          <Select
                            value={selectedThema?.id || ""}
                            onChange={(e) =>
                              setSelectedThema(
                                themas.find((t) => t.id === e.target.value)
                              )
                            }
                          >
                            {themas.map((thema) => (
                              <MenuItem key={thema.id} value={thema.id}>
                                {thema.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}

                      {subthemas?.length > 0 && (
                        <>
                          <InputLabel style={{ marginTop: "10px" }}>
                            Υποθέμα
                          </InputLabel>
                          <Select
                            value={selectedSubthema?.id || ""}
                            onChange={(e) =>
                              setSelectedSubthema(
                                subthemas.find((t) => t.id === e.target.value)
                              )
                            }
                          >
                            {subthemas.map((subthema) => (
                              <MenuItem key={subthema.id} value={subthema.id}>
                                {subthema.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </>
                  )}
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleCloseModal}
                  color="primary"
                  disabled={isLoading}
                >
                  Όχι
                </Button>
                <Button
                  onClick={handleConfirmAssignment}
                  color="primary"
                  autoFocus
                  disabled={
                    isLoading || (showSteppedSelects && !selectedSubthema)
                  }
                >
                  Ναι
                </Button>
              </DialogActions>
            </>
          )}
        </div>
      </Dialog>
      {showCheckForChanges ? (
          <CheckForChanges
              editMode={true}
              onCompletion={onUpdate}
              selectedSubThema={selectedSubthema?.id}
              groupAssignment={true}
              onCloseModal={resetToInitialState}
          />
      ) : null}
    </div>
  );
}

export default GroupAssignment;


  // const handleConfirmAssignment = async () => {
  //     try {
  //       setIsLoading(true);
  
  //       if (isThematicIdInTeam(selectedTeam, thematicId)) {
  //         const responseStatus = await assignTeamToTicket(
  //           ticket.id,
  //           selectedTeam.id
  //         );
  //         if (responseStatus === 200) {
  //           showSuccessMessage();
  //           resetToInitialState(); // Reset state when successful
  //           if (typeof onUpdate === "function") {
  //             onUpdate(true);
  //           }
  //         } else {
  //           showErrorMessage();
  //         }
  //         setMainSelection(selectedTeam);
  //         setOpenModal(false);
  //       } else {
  //         if (selectedSubthema) {
  //           await updateTicket(
  //             ticket.id,
  //             null,
  //             ticket.ticketFlagCollection,
  //             ticket.representativeId,
  //             ticket.description,
  //             selectedSubthema.id,
  //             ticket.beneficiaryId,
  //             ticket.ticketStatusId,
  //             false,
  //             ticket.assigneeUser,
  //             ticket.teamId,
  //             ticket.geographyId
  //           );
  
  //           const responseStatus = await assignTeamToTicket(
  //             ticket.id,
  //             selectedTeam.id
  //           );
  //           if (responseStatus === 200) {
  //             showSuccessMessage();
  //             setOpenModal(false);
  //             resetToInitialState();
  //             if (typeof onUpdate === "function") {
  //               onUpdate(true);
  //             }
  //           } else {
  //             showErrorMessage();
  //           }
  //         } else {
  //           setShowSteppedSelects(true);
  //         }
  //       }
  
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error during assignment:", error);
  //       showErrorMessage();
  //       setIsLoading(false);
  //     }
  //   };

