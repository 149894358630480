import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import routesConfig from './app/configs/routesConfig';
import DrawerWrapper from './app/Menu/DrawerWrapper';
import { AuthProvider, useAuth } from './app/auth/AuthContext';
import Message from './app/Message/MessageDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketStatusesAsync } from 'store/ticketCreateSlice';
import VersionChecker from 'VersionChecker';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.roleName);

  useEffect(() => {
    if (user) {
      dispatch(fetchTicketStatusesAsync());
    }
  }, [user, dispatch]);

  // Generate route configuration
  const elementRoutes = routesConfig.map((route) => {
    const RouteElement = route.inDrawer ? (
        <DrawerWrapper>{route.element}</DrawerWrapper>
    ) : (
        route.element
    );

    return {
      path: route.path,
      element: <ProtectedRoute route={route} RouteComponent={RouteElement} />,
    };
  });

  const routing = useRoutes(elementRoutes);

  return (
      <AuthProvider>
        <Message />
        <VersionChecker />
        {routing}
      </AuthProvider>
  );
};

const ProtectedRoute = ({ route, RouteComponent }) => {
  const { isAuthenticated } = useAuth();

  return route.protected && !isAuthenticated ? <Navigate to="/sign-in" /> : RouteComponent;
};

export default App;
