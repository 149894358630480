import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSearchValues,
    setSelectedUserId,
    setSelectedTeamIds, setAssignment
} from 'store/ticketFetchSlice';
import { jwtService } from 'app/auth/jwtService';
import { Switch } from '@mui/material';
import settingsConfig from 'app/configs/settingConfig';


function AssignToUserSwitch() {
    const dispatch = useDispatch();
    const userData = jwtService.getUser();
    const userId = userData?.id;
    const userRole = userData?.roleName;
    const userTeams = useSelector((state) => state.user.teams);
    const userTeamIds = userTeams?.map(team => team.id);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
    const { assignToMe, assignToTeam } = useSelector((state) => state.ticketFetchSlice.assignment);
    const assignment = useSelector((state) => state.ticketFetchSlice.assignment);



    const handleAssignToMeChange = (event) => {
        const isChecked = event.target.checked;
        dispatch(setAssignment({
            assignToMe: isChecked,
            assignToTeam: !isChecked ? assignment.assignToTeam : false
        }));

        if (isChecked) {
            dispatch(setSearchValues({ fieldName: 'assigneeUserId', value: userId }));
            dispatch(setSelectedUserId(userId));
        } else {
            dispatch(setSearchValues({ fieldName: 'assigneeUserId', value: null }));
            dispatch(setSelectedUserId(null));
        }
    };

    const handleAssignToTeamChange = (event) => {
        const isChecked = event.target.checked;
        dispatch(setAssignment({
            assignToTeam: isChecked,
            assignToMe: !isChecked ? assignment.assignToMe : false
        }));

        if (isChecked) {
            dispatch(setSelectedTeamIds([]));
            dispatch(setSearchValues({ fieldName: 'teams', value: userTeamIds }));
            dispatch(setSearchValues({ fieldName: 'assigneeUserId', value: null }));
            dispatch(setSelectedUserId(null));

        } else {
            dispatch(setSearchValues({ fieldName: 'teams', value: [] }));
        }
    };



    const isTeamLeader = settingsConfig.roles.teamLeader.includes(userRole);

    return (
        <div className="flex items-center">
            <Switch
                checked={assignToMe}
                onChange={handleAssignToMeChange}
                name="assignToMe"
                color="primary"
                disabled={ticketsLoading}
            />
            <span>Ανατεθειμένα σε μένα</span>

            {isTeamLeader && (
                <>
                    <Switch
                        checked={assignToTeam}
                        onChange={handleAssignToTeamChange}
                        name="assignToTeam"
                        color="primary"
                        disabled={ticketsLoading}
                    />
                    <span>Ανατεθειμένα στην ομάδα μου</span>
                </>
            )}
        </div>
    );
}

export default AssignToUserSwitch;
