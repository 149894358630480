import settingsConfig from "app/configs/settingConfig";
import {fetchCarrierByThematicId} from "app/Api Calls/TeamsRequiredApis";


export const calculateCanEditFlagGetTicketModel = async (ticket, userInfo, assignee) => {
    let flag = false;

    // Check user roles
    const isTeamLeader = settingsConfig.roles.teamLeader.includes(userInfo?.roleName);
    const isAgent = settingsConfig.roles.agent.includes(userInfo?.roleName);
    const isAdmin = settingsConfig.roles.admin.includes(userInfo?.roleName);
    const isSupervisor = settingsConfig.roles.supervisor.includes(userInfo?.roleName);
    const isTicketClosed = ticket?.ticketStatusId === 5;

    if (isAdmin) {
        // Admins can edit all tickets
        flag = true;
    } else if (isSupervisor) {
        // Supervisors can edit tickets with the same group ID or matching carrier ID
        const ticketGroupId = assignee?.roleCollection?.[0]?.group?.id || null;
        const userCarrierIds = userInfo?.carriers?.map(carrier => carrier.id) || [];
        let ticketCarrierId = null;

        try {
            if (ticket?.thematicId) {
                const response = await fetchCarrierByThematicId(ticket.thematicId);
                ticketCarrierId = response?.id || null;
            }
        } catch (error) {
            console.error("Error fetching carrier by thematic ID:", error);
        }

        if (ticketGroupId === userInfo?.userGroupId && ticketCarrierId && userCarrierIds.includes(ticketCarrierId)) {
            flag = true;
        }
    } else if ((isTeamLeader) && !isTicketClosed) {
        // Team Leaders and Agents can only edit tickets not in status 5 and within the same group role ID
        const ticketGroupId = assignee.roleCollection && assignee.roleCollection.length > 0
            ? assignee.roleCollection[0].group.id
            : null;
        const isTeamMember = (userInfo?.teams ?? []).some(team => team.id === ticket.teamId);

        if (ticketGroupId === userInfo?.userGroupId && isTeamLeader && isTeamMember) {
            flag = true;
        }

    } else if (isAgent && !isTicketClosed) {
        // Agents can only edit tickets assigned to them
        if (assignee.id === userInfo.id) {
            flag = true;
        }
    }

    return flag;
};
