// TicketItem.jsx
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/el';
import { axiosApi } from 'app/configs/axiosConfig';
import AppointmentDialog from './AppointmentDialog';
import { createAppointment } from "app/Api Calls/CreateAppointment";
import { useDispatch, useSelector } from "react-redux";
import settingsConfig from "app/configs/settingConfig";
import { showMessage } from "store/messageSlice";
import {useNavigate} from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.locale('el');

// Helper function to fetch the full thematic tree.
const fetchThematicTree = async (thematicId) => {
    try {
        const response = await axiosApi.get(`/thematic/${thematicId}/parent/tree`);
        return response.data;
    } catch (error) {
        console.error("Σφάλμα κατά την λήψη του θεματικού δέντρου:", error);
        throw error;
    }
};

// Build chain from a fully fetched tree object, with a fallback for the carrier title.
const buildChainFromTree = (tree, fallbackThematic) => {
    if (!tree) return "";
    let chain = [];
    let current = tree;
    while (current) {
        if (current.title) {
            chain.push(current.title);
        }
        current = current.parent;
    }
    chain.reverse(); // Highest-level title is now first.
    // Get carrier title from fetched tree or fallback thematic.
    const carrierTitle = tree.carrier?.title || fallbackThematic?.carrier?.title;
    if (carrierTitle) {
        chain.unshift(carrierTitle);
    }
    return chain.join(" -> ");
};

// Fallback: Build chain from ticket.thematic if the fetched tree is not available.
const buildThematicChain = (thematic) => {
    if (!thematic) return "";
    let chain = [];
    let current = thematic;
    while (current) {
        if (current.title) {
            chain.push(current.title);
        }
        current = current.parent;
    }
    chain.reverse();
    if (thematic.carrier && thematic.carrier.title) {
        chain.unshift(thematic.carrier.title);
    }
    return chain.join(" -> ");
};

const formatToGreekTime = (isoString) => {
    //Make the time to Greek Time from utc from the Database
    return dayjs(isoString).locale('el').format("DD/MM/YYYY HH:mm");
};

const RantebouTicketItem = ({ ticket, onAppointmentUpdate, hideAppointmentButton = false }) => {
    const [treeData, setTreeData] = useState(null);
    const [treeError, setTreeError] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Allow appointment button only if the user is an admin or a team leader.
    const foGroup = settingsConfig.groupRoles.find(group => group.shortName === "ROLE_FO");

    let allowedRoleIds = [];
    let foSupervisorRoleId = null;
    if (foGroup) {
        // Allowed roles: FO Supervisor and FO Team Leader
        allowedRoleIds = foGroup.roles
            .filter(role => ["ROLE_FO_SUPERVISOR", "ROLE_FO_TEAM_LEADER"].includes(role.roleName))
            .map(role => role.id);

        // Extract the FO Supervisor role id specifically
        const foSupervisorRole = foGroup.roles.find(role => role.roleName === "ROLE_FO_SUPERVISOR");
        foSupervisorRoleId = foSupervisorRole ? foSupervisorRole.id : null;
    }

    // Check if the ticket's team matches one of the user's teams.
    const isTeamMatch =
        ticket.teamId &&
        user.teams &&
        user.teams.some(team => team.id === ticket.teamId);

// canSetAppointment is true if the user is an admin or if the user's role ID
// is one of the allowed FO roles.
    const canSetAppointment =
        user.roleName === settingsConfig.roles.admin ||
        (allowedRoleIds.includes(user.userRoleId) && isTeamMatch);
    

// Check if the user is an FO Supervisor.
    const isFoSupervisor = foSupervisorRoleId && user.userRoleId === foSupervisorRoleId;


    const canPerformTeamAction =
        user.roleName === settingsConfig.roles.admin ||
        isFoSupervisor ||
        (isTeamMatch && canSetAppointment) ||
        (ticket.assigneeUser && ticket.assigneeUser.id === user.id);

    useEffect(() => {
        if (expanded && ticket.thematic && ticket.thematic.id && !treeData) {
            fetchThematicTree(ticket.thematic.id)
                .then((data) => setTreeData(data))
                .catch((error) => setTreeError("Αποτυχία λήψης θεματικού δέντρου."));
        }
    }, [expanded, ticket, treeData]);

    //Function to send the appointment to the backend (Already in UTC TIME)
    const handleSelectAppointment = (selectedDateTime) => {
        // Convert the selected appointment time to UTC before sending to the database.
        createAppointment(ticket.id, selectedDateTime)
            .then(() => {
                dispatch(showMessage({
                    message: 'Το ραντεβού ενημερώθηκε με επιτυχία',
                    autoHideDuration: 6000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                }));
                if (onAppointmentUpdate) {
                    onAppointmentUpdate();
                }
            })
            .catch((error) => {
                dispatch(showMessage({
                    message: 'Αποτυχία ενημέρωσης ραντεβού',
                    autoHideDuration: 6000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                }));
                console.error("Αποτυχία ενημέρωσης ραντεβού.", error);
            })
            .finally(() => {
                setAppointmentDialogOpen(false);
            });
    };

    const handleTeamButtonClick = (e) => {
        e.stopPropagation();
        navigate(`/edit-ticket/${ticket.id}`);
    };

    return (
        <Accordion onChange={(e, isExpanded) => setExpanded(isExpanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} component="div">
                <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                            <strong style={{ marginRight: "8px" }}>{ticket.uuid}</strong>
                            {ticket.beneficiary
                                ? `${ticket.beneficiary.firstName} ${ticket.beneficiary.lastName}`
                                : "Χωρίς Δικαιούχο"}
                            {ticket.createdAt && ` - ${formatToGreekTime(ticket.createdAt)}`}
                        </Typography>
                        <Box display="flex" alignItems="center">

                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    my: 'auto',
                                    mr: 1,
                                    backgroundColor: '#ed6c02 !important',
                                    color: 'white',
                                    '&.Mui-disabled': {
                                        backgroundColor: 'grey !important',
                                        color: 'white !important'
                                    }
                                }}
                                onClick={handleTeamButtonClick}
                                disabled={!canPerformTeamAction}
                            >
                                Επεξεργασία
                            </Button>


                            {(!hideAppointmentButton && canSetAppointment) && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ my: 'auto' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAppointmentDialogOpen(true);
                                    }}
                                >
                                    {ticket.appointmentCalendar && ticket.appointmentCalendar.scheduledDateTime
                                        ? 'Αλλαγή Καθορισμένου Ραντεβού'
                                        : 'Ορισμός Ραντεβού'}
                                </Button>
                            )}
                        </Box>
                    </Box>
                    {ticket.appointmentCalendar && (
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mt: 0.5 }}>
                            {ticket.appointmentCalendar.scheduledDateTime
                                ? `Καθορισμένο Ραντεβού: ${formatToGreekTime(ticket.appointmentCalendar.scheduledDateTime)}`
                                : "Το ραντεβού δεν έχει καθοριστεί"}
                        </Typography>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Typography variant="body2">
                        Αριθμός Ticket: {ticket.uuid || "Χωρίς Αριθμό Ticket"}<br />
                        Περιγραφή: {ticket.description || "Χωρίς Περιγραφή"}<br />
                        Κατάσταση: {ticket.ticketStatus?.name || "Δεν υπάρχει"}<br />
                    </Typography>
                    {ticket.thematic && (
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Θεματική Αλυσίδα:
                            </Typography>
                            <Typography variant="body2">
                                {treeData
                                    ? buildChainFromTree(treeData, ticket.thematic)
                                    : buildThematicChain(ticket.thematic)}
                            </Typography>
                        </Box>
                    )}
                    {ticket.beneficiary && (
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Στοιχεία Δικαιούχου:
                            </Typography>
                            <Typography variant="body2">
                                Ονοματεπώνυμο: {ticket.beneficiary.firstName} {ticket.beneficiary.lastName}<br />
                                ΑΦΜ: {ticket.beneficiary.afm}<br />
                                Τηλέφωνο: {ticket.beneficiary.phone || "Δεν υπάρχει"}<br />
                                Κινητό: {ticket.beneficiary.mobile || "Δεν υπάρχει"}<br />
                                Διεύθυνση: {ticket.beneficiary.address || "Δεν υπάρχει"}
                            </Typography>
                        </Box>
                    )}
                    {ticket.appointmentCalendar && (
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Προτεινόμενες Ημερομηνίες & Ώρες Ραντεβού:
                            </Typography>
                            <Typography variant="body2">
                                Πρώτη Επιλογή: {ticket.appointmentCalendar.firstDateTimeChoice
                                ? formatToGreekTime(ticket.appointmentCalendar.firstDateTimeChoice)
                                : '-'}<br />
                                Δεύτερη Επιλογή: {ticket.appointmentCalendar.secondDateTimeChoice
                                ? formatToGreekTime(ticket.appointmentCalendar.secondDateTimeChoice)
                                : '-'}<br />
                                Τρίτη Επιλογή: {ticket.appointmentCalendar.thirdDateTimeChoice
                                ? formatToGreekTime(ticket.appointmentCalendar.thirdDateTimeChoice)
                                : '-'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </AccordionDetails>
            <AppointmentDialog
                open={appointmentDialogOpen}
                onClose={() => setAppointmentDialogOpen(false)}
                onSelectAppointment={handleSelectAppointment}
                ticketId={ticket.id}
                ticketUuid={ticket.uuid}
            />
        </Accordion>
    );
};

export default RantebouTicketItem;
