import React, { useState, useEffect } from 'react';
import {
    fetchAllCarriers,
    fetchThematicsByCarrierIdCall,
    fetchSubSubthematic
} from "app/Api Calls/CarriersThematicsCalls";
import CategoriesDropdown from "../Reusable Components/CategoriesDropdown";
import Button from "@mui/material/Button";
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Switch
} from "@mui/material";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import {
    createCarrier,
    createQuestionNAnswer,
    createThematic
} from "app/Api Calls/GnosiakiCalls";
import { triggerGlobalRefresh } from "store/gnosiakiCrudSlice";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";

const TreeComponent = () => {
    // Hierarchy for dropdowns
    const [hierarchy, setHierarchy] = useState([{ items: [], selectedId: null }]);
    // Text input fields
    const [newItemName, setNewItemName] = useState('');
    const [question, setQuestion] = useState('');
    const [customId, setCustomId] = useState('');

    // Editor state for QnA answer
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // New switches state for adding items (for carriers/thematics)
    const [newItemIsActive, setNewItemIsActive] = useState(true);
    const [newItemIsPublished, setNewItemIsPublished] = useState(true);

    // New switches state for Q&A entries
    const [qnaIsActive, setQnaIsActive] = useState(true);
    const [qnaIsPublished, setQnaIsPublished] = useState(true);

    const dispatch = useDispatch();

    // Show Q&A section when hierarchy is deep enough and no further options exist.
    const showQnASection = hierarchy.length > 3 && hierarchy[hierarchy.length - 1].items.length === 0;

    const fetchData = async (level, parentId = null) => {
        let data;
        if (level === 0) {
            data = await fetchAllCarriers();
        } else if (level === 1) {
            data = await fetchThematicsByCarrierIdCall(parentId);
        } else {
            data = await fetchSubSubthematic(parentId);
        }
        return data.map(item => ({ value: item.id, label: item.title }));
    };

    const handleSelect = (level, selectedOption) => {
        const newHierarchy = hierarchy.slice(0, level + 1);
        const selectedId = selectedOption ? selectedOption.value : null;
        if (selectedId) {
            fetchData(level + 1, selectedId).then(data => {
                newHierarchy[level] = { ...newHierarchy[level], selectedId };
                newHierarchy.push({ items: data, selectedId: null });
                setHierarchy(newHierarchy);
            });
        } else {
            newHierarchy[level] = { items: newHierarchy[level].items, selectedId: null };
            setHierarchy(newHierarchy);
        }
    };

    const handleAddNewItem = async (level) => {
        try {
            let response;
            // For thematic levels beyond carriers, determine parentId and carrierId accordingly.
            const parentId = level === 1 ? null : hierarchy[level - 2]?.selectedId;
            const carrierId = hierarchy[0]?.selectedId;
            if (level === 1) {
                // Adding a carrier: only pass isActive and customId.
                response = await createCarrier({
                    title: newItemName,
                    isActive: newItemIsActive,
                    customId: customId,
                });
                dispatch(triggerGlobalRefresh({ carrierId: response.data.id }));
            } else {
                // Adding a thematic: include both isActive and isPublished.
                response = await createThematic({
                    title: newItemName,
                    isActive: newItemIsActive,
                    externalId: null,
                    parentId: level === 2 ? null : parentId,
                    carrierId: carrierId,
                    isPublished: newItemIsPublished,
                });
                dispatch(triggerGlobalRefresh({ parentId }));
            }
            // Reset new item fields and switches
            setNewItemName('');
            setCustomId('');
            setNewItemIsActive(true);
            setNewItemIsPublished(true);
            await refetchCurrentLevel(level, parentId);
        } catch (error) {
            console.error("Failed to add new item:", error);
        }
    };

    const refetchCurrentLevel = async (level, parentId) => {
        let newData;
        if (level === 1) {
            newData = await fetchAllCarriers();
        } else if (level === 2) {
            newData = await fetchThematicsByCarrierIdCall(parentId);
        } else {
            newData = await fetchSubSubthematic(parentId);
        }
        const newLevelData = {
            items: newData.map(dataItem => ({
                value: dataItem.id,
                label: dataItem.title || dataItem.name
            })),
            selectedId: null
        };
        const adjustedLevel = level - 1;
        let updatedHierarchy;
        if (adjustedLevel < hierarchy.length) {
            updatedHierarchy = hierarchy.map((item, index) => index === adjustedLevel ? newLevelData : item);
        } else {
            updatedHierarchy = [...hierarchy, newLevelData];
        }
        setHierarchy(updatedHierarchy);
    };

    const handleAddQnA = async () => {
        try {
            let answerHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            answerHtml = DOMPurify.sanitize(answerHtml);
            answerHtml = answerHtml.replace(/\n+$/, '');
            if (answerHtml !== "") {
                const thematicId = hierarchy[hierarchy.length - 2]?.selectedId;
                const response = await createQuestionNAnswer({
                    title: question,
                    question,
                    answer: answerHtml,
                    isActive: qnaIsActive,
                    thematicId,
                    isPublished: qnaIsPublished
                });
                console.log(response);
                setQuestion('');
                setEditorState(EditorState.createEmpty());
                // Optionally reset QnA switches as well
                setQnaIsActive(true);
                setQnaIsPublished(true);
                dispatch(triggerGlobalRefresh({ parentId: thematicId }));
            }
        } catch (error) {
            console.error("Failed to add QnA:", error);
        }
    };

    useEffect(() => {
        fetchData(0).then(data => {
            setHierarchy([{ items: data, selectedId: null }]);
        });
    }, []);

    const removeHtmlTags = (htmlString) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = htmlString;
        return tmp.textContent || tmp.innerText || "";
    };

    return (
        <Card variant="outlined" sx={{ maxWidth: "90vw", margin: 'auto', mt: 4 }}>
            <CardContent>
                <Typography variant="h5" component="div" align="center" gutterBottom>
                    Προσθήκη Φορέων / Θεματικών / Ερωτοαπαντήσεων
                </Typography>
                {hierarchy.map((levelData, index) => (
                    <Grid
                        container
                        key={index}
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 1 }}
                    >
                        <Grid item xs={3}>
                            <CategoriesDropdown
                                options={levelData.items}
                                value={levelData.items.find(
                                    (item) => item.value === levelData.selectedId
                                )}
                                onChange={(selectedOption) =>
                                    handleSelect(index, selectedOption)
                                }
                                placeholder={
                                    index === 0
                                        ? "Επιλέξτε Φορέα"
                                        : index === 1
                                            ? "Εισάγετε Θεματική Ενότητα"
                                            : index === 2
                                                ? "Εισάγετε Θέμα"
                                                : index === 3
                                                    ? "Εισάγετε Υποθέμα"
                                                    : "No options available"
                                }
                                isLoading={false}
                                backgroundColor="#fff"
                                isMultiSelect={false}
                                isDisabled={false}
                            />
                        </Grid>
                        {(index === hierarchy.length - 1 && hierarchy.length <= 4) && (
                            <Grid item xs={9}>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item  sx={{width: "40%"}}>
                                        <TextField
                                            label="Εισάγετε κείμενο"
                                            value={newItemName}
                                            onChange={(e) => setNewItemName(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            sx={{width: "100%"}}
                                        />
                                    </Grid>
                                    {index === 0 && (
                                        <Grid item>
                                            <TextField
                                                label="Custom ID"
                                                value={customId}
                                                onChange={(e) => setCustomId(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        {index === 0 ? (
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Switch
                                                    checked={newItemIsActive}
                                                    onChange={(e) => setNewItemIsActive(e.target.checked)}
                                                    color="primary"
                                                />
                                                <Typography variant="body2">Is Active(Ticketing)</Typography>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    <Switch
                                                        checked={newItemIsActive}
                                                        onChange={(e) => setNewItemIsActive(e.target.checked)}
                                                        color="primary"
                                                    />
                                                    <Typography variant="body2">Is Active(Ticketing)</Typography>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    <Switch
                                                        checked={newItemIsPublished}
                                                        onChange={(e) => setNewItemIsPublished(e.target.checked)}
                                                        color="primary"
                                                    />
                                                    <Typography variant="body2">Is Published(WebChannel)</Typography>
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            disabled={
                                                newItemName.length < 3 ||
                                                (index === 0 && customId.trim().length === 0)
                                            }
                                            onClick={() => handleAddNewItem(index + 1)}
                                            sx={{
                                                backgroundColor:
                                                    newItemName.length < 3 ||
                                                    (index === 0 && customId.trim().length === 0)
                                                        ? '#d3d3d3 !important'
                                                        : '#003476 !important',
                                                '&:hover': {
                                                    backgroundColor:
                                                        newItemName.length < 3 ||
                                                        (index === 0 && customId.trim().length === 0)
                                                            ? '#d3d3d3'
                                                            : '#0056b3',
                                                },
                                                color: (theme) =>
                                                    theme.palette.getContrastText(
                                                        newItemName.length < 3 ||
                                                        (index === 0 && customId.trim().length === 0)
                                                            ? '#d3d3d3'
                                                            : '#003476'
                                                    ),
                                            }}
                                        >
                                            ΠΡΟΣΘΗΚΗ
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ))}
                {showQnASection && (
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Ερώτηση"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">Απάντηση</Typography>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                placeholder="Προσθέστε Απάντηση"
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'fontFamily',
                                        'list',
                                        'textAlign',
                                        'history'
                                    ],
                                    inline: {
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                    },
                                    blockType: { dropdownClassName: 'dropdown-classname' },
                                    fontSize: {},
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        dropdownClassName: 'dropdown-classname'
                                    },
                                    list: { options: ['unordered', 'ordered', 'indent', 'outdent'] },
                                    textAlign: { options: ['left', 'center', 'right', 'justify'] },
                                    history: { options: ['undo', 'redo'] },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center" direction="row">
                                <Grid item>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Switch
                                            checked={qnaIsActive}
                                            onChange={(e) => setQnaIsActive(e.target.checked)}
                                            color="primary"
                                        />
                                        <Typography variant="body2">Is Active(Ticketing)</Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Switch
                                            checked={qnaIsPublished}
                                            onChange={(e) => setQnaIsPublished(e.target.checked)}
                                            color="primary"
                                        />
                                        <Typography variant="body2">Is Published(Webchannel)</Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        onClick={handleAddQnA}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                question.length < 10 ||
                                                removeHtmlTags(
                                                    draftToHtml(convertToRaw(editorState.getCurrentContent()))
                                                ).length < 10
                                                    ? '#d3d3d3 !important'
                                                    : '#003476 !important',
                                            color: (theme) =>
                                                question.length < 10 ||
                                                removeHtmlTags(
                                                    draftToHtml(convertToRaw(editorState.getCurrentContent()))
                                                ).length < 10
                                                    ? theme.palette.getContrastText('#d3d3d3')
                                                    : theme.palette.getContrastText('#003476'),
                                        }}
                                    >
                                        Προσθήκη Ερώτησης
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default TreeComponent;
