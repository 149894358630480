import Error404Page from "app/401/Error404Page";
import Authorization from "app/auth/Authorization";
import UsersTable from "app/main/Panels/UserPanel/UserTable";
import ChangePassword from "app/main/Sign-in/ChangePassword";
import Profile from "app/main/Sign-in/Profile";
import SignInPage from "app/main/Sign-in/SignInPage";
import SignOutPage from "app/main/Sign-out/SignOutPage";
import SignUpPageAdmin from "app/main/Sign-up/SignUpPageAdmin";
import TeamsCrud from "app/main/Teams/TeamsCRUD";
import KnowledgePageCRUD from "app/main/gnosiakiCrud/KnowledgePageCRUD";
import EditPage from "app/main/tickets/CRUD Ticket/EditTicket/EditPage";
import TicketDetails from "app/main/tickets/CRUD Ticket/TicketDetails";
import TicketsPage from "app/main/tickets/CRUD Ticket/TicketsPage";
import KnowledgeBasePage from "app/main/tickets/create-ticket/KnowledgeBasePage";
import { Navigate } from "react-router-dom";
import settingsConfig from "./settingConfig";
import Taxis from "app/main/Sign-in/Taxis";
import AnnouncmentsPage from "app/Announcments/AnnouncmentsPage";
import AnnouncmentsAdminPage from "app/Announcments/Admin/AnnouncmentsAdminPage";
import BeneficiaryPanelPage from "app/main/Panels/BeneficiaryPanel/BeneficiaryPanelPage";
import StandardTextPage from "app/main/Panels/StandardTexts/StandardTextPage";
import RantebouPage from "app/main/Rantebou/RantebouPage";


const routesConfig = [
  {
    path: '/',
    element: <Navigate to="/create-ticket" replace />,
    inDrawer: false,
    protected: true,
  },
  {
    path: '/create-ticket',
    element: <KnowledgeBasePage />,
    inDrawer: true,
    protected: true,
  },
  {
    path: 'tnetauth',
    element:
      <Taxis/>
    ,
    protected: false,
    inDrawer: false,
  },
  {
    path: 'sign-in',
    element: (
      <Authorization>
        <SignInPage />
      </Authorization>
    ),
    inDrawer: false,
    protected: false,
  },
  {
    path: '/sign-out',
    element: <SignOutPage />,
    inDrawer: false,
    protected: true,
  },
  {
    path: '*',
    element: <Error404Page />,
    inDrawer: false,
    protected: false,
  },
  {
    path: 'profile',
    element: <Profile />,
    inDrawer: true,
    protected: true,

  },
  {
    path: 'tickets',
    element: <TicketsPage />,
    inDrawer: true,
    protected: true,

  },
  {
    path: 'standard-texts',
    element: <StandardTextPage />,
    inDrawer: true,
    protected: true,

  },
  {
    path: 'change-password',
    element: <ChangePassword />,
    inDrawer: true,
    protected: true,
  },
  {
    path: 'ticket/:id',
    element: <TicketDetails />,
    inDrawer: true,
    protected: true,
  },
  {
    path: 'edit-ticket/:id',
    element: (
      <EditPage />
    ),
    inDrawer: true,
    protected: true,
  },

  {
    path: '/sign-up',
    element: (
      <Authorization allowedRoles={[settingsConfig.roles.admin]}>
        <SignUpPageAdmin />
      </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },

  {
    path: '/users',
    element: (
      <Authorization allowedRoles={[settingsConfig.roles.admin]}>
        <UsersTable />
      </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
  {
    path: '/beneficiary-panel',
    element: (
        <Authorization allowedRoles={[settingsConfig.roles.admin, ...settingsConfig.roles.supervisor]}>
          <BeneficiaryPanelPage />
        </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
  {
    path: '/rantebou',
    element: <RantebouPage />,
    inDrawer: true,
    protected: true,
  },
  {
    path: '/teams',
    element: (
      <Authorization allowedRoles={[settingsConfig.roles.admin]}>
        <TeamsCrud />
      </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
  {
    path: '/announcments-page',
    element: (
        <Authorization allowedRoles={[settingsConfig.roles.admin]}>
          <AnnouncmentsAdminPage />
        </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
  {
    path: '/announcments',
    element: (
        <Authorization>
          <AnnouncmentsPage />
        </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
  {
    path: '/knowledge-base',
    element: (
      <Authorization allowedRoles={[settingsConfig.roles.admin]}>
        <KnowledgePageCRUD />
      </Authorization>
    ),
    inDrawer: true,
    protected: true,
  },
];

export default routesConfig;
