export function validateName(name) {
  if (!name) return null;
  const regex = /^(?!.*(-.*-|\s.*\s|-.*\s|\s.*-))^[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+([-|\s]?[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+)?$/;

  return regex.test(name)
      ? null
      : 'Το όνομα πρέπει να περιέχει μόνο γράμματα (συμπεριλαμβανομένων όσων φέρουν διαλυτικά), μία κενή θέση ή μία παύλα και δεν μπορεί να αποτελείται μόνο από κενά ή παύλες ή να περιέχει και τα δύο στοιχεία';
}

export function validateSurname(surname) {
  if (!surname) return null;
  const regex = /^(?!.*(-.*-|\s.*\s|-.*\s|\s.*-))^[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+([-|\s]?[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+)?$/;

  return regex.test(surname)
      ? null
      : 'Το επώνυμο πρέπει να περιέχει μόνο γράμματα (συμπεριλαμβανομένων όσων φέρουν διαλυτικά), μία κενή θέση ή μία παύλα και δεν μπορεί να αποτελείται μόνο από κενά ή παύλες ή να περιέχει και τα δύο στοιχεία';
}

export function validateUserSurname(surname) {
  if (!surname) return null;
  const regex = new RegExp(
      '^(?!.*(-.*-|\\s.*\\s|-.*\\s|\\s.*-))'
      + '^[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+'
      + '(([-|\\s]?[a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+)*)?'
      + '(\\s?\\([a-zA-Zα-ωΑ-Ωά-ώΆ-ΏϊϋΐΰΪΫ]+\\))?$'
  );

  return regex.test(surname)
      ? null
      : 'Το επώνυμο πρέπει να περιέχει μόνο γράμματα (συμπεριλαμβανομένων όσων φέρουν διαλυτικά), μία κενή θέση ή μία παύλα, μπορεί να περιλαμβάνει παρενθέσεις (π.χ. admin(cev) ή admin (cev)) και δεν μπορεί να αποτελείται μόνο από κενά ή παύλες ή να περιέχει και τα δύο στοιχεία';
}
  
  
  export function validateAMKA(amka) {
    if (!amka) return null;
    const regex = /^\d{11}$/;
    return regex.test(amka) ? null : 'Το ΑΜΚΑ πρέπει να αποτελείται από 11 ψηφία';
  }
  
  export function validateAFM(afm) {
    if (!afm) return null;
    const regex = /^\d{9}$/;
    return regex.test(afm) ? null : 'Το ΑΦΜ πρέπει να αποτελείται από 9 ψηφία';
  }

export function validateEmail(email) {
  if (!email) return null;
  const regex = /^[^\s@]+@[^\s@]+\.(gr|com|[a-z]+)$/;
  return regex.test(email) ? null : 'Η διεύθυνση email πρέπει να είναι έγκυρη';
}


export function validatePhone(phone) {
    if (!phone) return null;
    
    // Validate if the phone number is exactly 10 digits and contains only numbers.
    const regex = /^\d{10}$/;
  
    return regex.test(phone) ? null : 'Ο αριθμός τηλεφώνου πρέπει να αποτελείται από 10 ψηφία';
  }
  
  
  
  export function validateMobile(mobile) {
    if (!mobile) return null;
    // This regex checks for a number starting with 69 and followed by 8 digits
    const regex = /^69\d{8}$/;
    return regex.test(mobile)
      ? null
      : 'Ο αριθμός τηλεφώνου πρέπει να ξεκινά με 69 και να αποτελείται από 10 ψηφία';
  }
  
  export function isValidCallerId(callerId) {
    if (callerId === '#') {
      return null; 
    }
  
    const pattern = /^\d{10}$/;
    if (!pattern.test(callerId)) {
      return "Πρέπει να περιέχει 10 ψηφία";
    }
    
    return null;
  }
  
  
  
  export const validateAddress = (address) => {
    if (!address) return null;
    if (address.length < 5) {
      return 'Η διεύθυνση πρέπει να είναι τουλάχιστον 5 χαρακτήρες';
    }
    return null;
  };

export function validateProfession(profession) {
  if (!profession) return null;
  if (profession.length < 3) {
    return 'Το επάγγελμα πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες';
  }
  return null;
}

export function validateSpecialInformation(specialInformation) {
  if (!specialInformation) return null;
  if (specialInformation.length < 3) {
    return 'Οι ειδικές πληροφορίες πρέπει να περιέχουν τουλάχιστον 3 χαρακτήρες';
  }
  return null;
}


  
  
  